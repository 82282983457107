import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 10px 0;
  label {
    padding-left: 15px;
    text-align: left;
    font-size: 12px;
    font-weight: 700;
    color: #8a92a2;
  }
  .select {
    // margin: 10px 0 0;
    &.error {
      border: 1px solid #f01;
      border-radius: 25px;
    }
  }
  
  .css-8m1df9-placeholder{
    margin-left: ${props => (props.state ? "30px" : "")};
  }

  .css-1uccc91-singleValue{
    margin-left: ${props => (props.state ? "30px" : "")};
  }

  .css-107lb6w-singleValue{
    margin-left: ${props => (props.state ? "30px" : "")};
  }
  .css-1bmx7s-control{
    flex-wrap: nowrap;
  }

  .css-1bc799y-control{
    flex-wrap: nowrap;
  }

  .pin-estado{
    width: 20px;
    position: relative;
    margin-top: -39px;
    /* margin-bottom: 30px; */
    left: 15px;

  }



  option{
    background-color: red;
  }
  
`;
