import styled from "styled-components";

export const CardContainer = styled.div`
    width: ${props => (props.width ? props.width : '100%')};
    height: ${props => (props.height ? props.height : '100%')};
    display: flex;
    flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'row')};
    flex-wrap: wrap;
    background-color: #fff;
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'left')};
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    padding: ${props => (props.padding ? props.padding : '15px')};
    border: 1px solid #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0px')};
    
    @media only screen and (max-width:  ${props => (props.isResponsive ? ( props.isResponsiveWhenWidth ? props.isResponsiveWhenWidth : '0px' ) : '0px')}) {
        width: 100%;
        height: ${props => (props.height ? props.height : '100%')};
        margin-bottom: 15px;
    }
    
    @media only screen and (max-width: 630px) {
        height: ${props => (props.height ? props.height : '100%')};
    }

`;