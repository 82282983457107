import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router';
import Header from '../../components/header';
import Input from '../../components/input-search';
import Button from '../../components/button';
import Table from '../../components/table';
import api from '../../services/api';
import { store } from "react-notifications-component";


import {
    UsuariosContainer,
    SearchAndAdd,
} from './styles';
import { formatUsersForTable } from '../../utils/formatData';
import Loading from '../../components/loading';


const Usuarios = ({ ...props }) => {

    const [load, setLoad] = useState(false);
    const [tableInfo, setTableInfo] = useState([]);
    const [search, setSearch] = useState('');
    const [erroUsers, setErroUsers] = useState(false);


    const onChangeSearch = (value) => {
        if (value !== search) {
            setSearch(value);
        }
    }

    const searchUsers = (value) => {
        console.log(`Pesquisar por '${value}'`);
    }

    const typedKey = (key, keyCode) => {
        if (key === 'Enter' && keyCode === 13) {
            searchUsers(search);
        }
    }

    const redirectTo = (location) => {
        props.history.push(location);
    }

    const getUsers = async () => {
        try {
            const response = await api.get('/agronomos');
            const users = formatUsersForTable(response.data.data);

            const columns = ['Nome', 'User_id', 'Cliente', 'CPF', 'Cidade', 'Estado']
            const data = users;

            setTableInfo({columns: columns, data: data});

        } catch (error) {
            const columns = ['Nome', 'Cliente', 'CPF', 'Cidade', 'Estado']
            const data = [];
            setTableInfo({columns: columns, data: data});
            setErroUsers(true);

            if (error.response.data.errors === 'Nenhum agronomo encontrado') {
                store.addNotification({
                    title: "Erro!",
                    message: `Nenhum usuário encontrado`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
            
        }
    }

    const initializeComponent = () => {
        if (load === false) {
            setLoad(true);
            setTimeout(() => {
                getUsers();
            }, 500);
        }
    }

    useEffect(() => {
        initializeComponent();
    })

    return (
        <>
            <Header title="Usuários" subtitle='agrônomos' align='center'/>
            <UsuariosContainer>
                <SearchAndAdd>
                    <Input 
                        className='search'
                        placeholder='Nome'
                        onChange={(e) => onChangeSearch(e.target.value)}
                        value={search}
                        onKeyDown={(e) => typedKey(e.key, e.keyCode)}
                        onClickSearch={() => searchUsers(search)}
                    />
                    <Button
                        onClick={() => redirectTo("/usuarios/add")}
                    >Adicionar usuário</Button>
                </SearchAndAdd>
                {tableInfo.columns && tableInfo.data ? (
                    <Table data={tableInfo} route={(id) => redirectTo(`/usuarios/edit/${id}`)}/>
                    ):(
                        <>
                        {erroUsers ? (
                            <Table data={tableInfo} route={(id) => redirectTo(`/usuarios/edit/${id}`)}/>
                        ) : (
                            <Loading />
                        )}
                    </>
                )}   
                    </UsuariosContainer>
        </>
    )
}
export default withRouter(Usuarios);
