import styled, { createGlobalStyle } from "styled-components";

import "simplebar/dist/simplebar.min.css";
import "react-notifications-component/dist/theme.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;

}

body, html {
  font-family: 'Lato', sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  height: 0%;
  width: 100%;
  background-color: #f8f8f9;

}

a {
  text-decoration: none;
  color: #599bff;
  cursor: pointer;
}

input {
  /* margin: 10px 0 0; */
  font-weight: 600;
  width: 100%;
  /* max-width: 400px; */
  height: 50px;
  border: none;
  font-size: 16px;
  border-radius: 25px;
  /* box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05); */
  padding: 15px 25px;
  border: 1px solid #ced2dc;

  &.error {
    border: 1px solid #f01;
  }
  &::placeholder {
    color: #ced2dc;
  }

}
`;

export default GlobalStyle;

export const Layout = styled.div`
  display: flex;
  span {
    width: 100%;
  }
`;

export const Main = styled.span`
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

`;
