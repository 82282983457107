import React, { useEffect, useRef } from "react";

import InputMask from "inputmask";

// import { InputContainer } from "./styles";

const Input = ({ ...props }) => {

  const inputEl = useRef(null);
  
  useEffect(() => {
    let input = inputEl.current;
    let im = new InputMask(props.mask, { showMaskOnHover: false });
    im.mask(input);

  })

  return (
    <div 
      className="inputContainer"
      style={{
        margin: '10px 0',
        width: '100%',
        maxWidth: '400px',
      }}
    >
      <label 
        style={{
          paddingLeft: '15px',
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: '700',
          color: '#8a92a2',
          display : props.label === ' ' ? 'none' : 'flex',
        }}
      >{props.label || props.placeholder}</label>
      <input  
        style={{
          marginTop: '10px'
        }}
        ref={inputEl} 
        {...props} 
      ></input> 
      {props.unidade? (
        <label 
          className="unidadeDose"
          style={{
            position: 'absolute',
            right: '17%',
            marginTop: '22px',
            fontSize: '16px',
            color: '#dce3eb',
          }}
        > {props.unidade} </label>
      ):(null)}
    </div>
  );
}

export default Input;
