import styled from "styled-components";

export const RelatoriosContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
`;

export const Card = styled.div`
    width: 260px;
    height: 280px;
    background-color: #f8f8f9;
    border-radius: 10px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: rgb(29 33 41 / 4%) 0px 0px 10px 10px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px;
    transition: all 0.3s ease 0s;
    &:hover{
        background-color: #fff;
        transform: scale(1.06);
    }

    h2 {
        font-family: Lato, sans-serif;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: center;
    }

    img {
        width: 170px;
    }
`;
