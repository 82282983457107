import React, { useState, useEffect } from 'react';
import Input from '../../input';
import Select from '../../select';
import Button from '../../button';
import { store } from "react-notifications-component";
import viacep from "../../../services/viacep";

import {
    EmpresaContainer,
    CadContainer,
} from './styles';
import api from '../../../services/api';
// import validadorDeCpf from '../../../services/validadorCpf';

const EmpresaForm = ({ ...props }) => {

    const [nomeFantasia, setNomeFantasia] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [cnpj, setCnpj] = useState('');
    // const [cpf, setCpf] = useState('');
    // const [ /* cpfIsValid */, setCpfIsValid] = useState(false);
    const [telefone, setTelefone] = useState('');
    // const [telefone2, setTelefone2] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    // const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState([]);
    // const [ie, setIe] = useState('');
    const [load, setLoad] = useState(false);
    const [estados] = useState([
        { value: "SP", label: "São Paulo" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "SC", label: "Santa Catarina" },
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapa" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espirito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piaui" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" },
        { value: "", label: "" }
    ]);

    const changeNomeFantasia = (value) => {
        if (value !== nomeFantasia) {
            setNomeFantasia(value);
        }
    }
    const changeRazaoSocial = (value) => {
        if (value !== razaoSocial) {
            setRazaoSocial(value);
        }
    }
    const changeCnpj = (value) => {
        if (value !== cnpj) {
            setCnpj(value)
        }
    }
    // const changeCpf = (value) => {
    //     if (value !== cpf) {
    //         setCpf(value);
    //         const input = document.getElementById('cpf');
    //         input.classList.remove("error");
    //     }
    // }
    const changeTelefone = (value) => {
        if (value !== telefone) {
            setTelefone(value);
        }
    }
    // const changeTelefone2 = (value) => {
    //     if (value !== telefone2) {
    //         setTelefone2(value);
    //     }
    // }
    const changeCep = (value) => {
        if (value !== cep) {
            setCep(value);
            const input = document.getElementById('cep');
            input.classList.remove("error");
        }
    }
    const changeLogradouro = (value) => {
        if (value !== logradouro) {
            setLogradouro(value);
        }
    }
    const changeNumero = (value) => {
        if (value !== numero) {
            setNumero(value);
        }
    }
    // const changeComplemento = (value) => {
    //     if (value !== complemento) {
    //         setComplemento(value);
    //     }
    // }
    const changeBairro = (value) => {
        if (value !== bairro) {
            setBairro(value);
        }
    }
    const changeCidade = (value) => {
        if (value !== cidade) {
            setCidade(value);
        }
    }
    const changeEstado = (value) => {
        if (value !== estado) {
            setEstado(value);
        }
    }
    // const changeIe = (value) => {
    //     if (value !== ie) {
    //         setIe(value);
    //     }
    // }


    const validaCep = async () => {
        try {
            if (cep) {
                const response = await viacep.get(cep + "/json");
                if (response.data.erro) {
                    const input = document.getElementById('cep');
                    input.classList.add("error");
    
                    changeLogradouro('');
                    changeBairro('');
                    changeCidade('');
                    changeEstado('');
    
                    store.addNotification({
                        title: "Erro!",
                        message: "CEP inserido é inválido.",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                } else {
                    const info = response.data;
        
                    const { logradouro, bairro, localidade, uf } = info;
                    
                    const input = document.getElementById('cep');
                    input.classList.remove("error");
                    
                    changeLogradouro(logradouro);
                    changeBairro(bairro);
                    changeCidade(localidade);
                    changeEstado(uf);
                }
            } else {
                const input = document.getElementById('cep');
                input.classList.remove("error");
            }
        } catch (error) {

            const input = document.getElementById('cep');
            input.classList.add("error");

            changeLogradouro('');
            changeBairro('');
            changeCidade('');
            changeEstado('');

            store.addNotification({
                title: "Erro!",
                message: "CEP inserido é inválido.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    }

    // const validaCpf = () => {
    //     if (cpf) {
    //         const isValido = validadorDeCpf(cpf);

    //         if (isValido) {
    //             setCpfIsValid(true);
    //             const input = document.getElementById('cpf');
    //             input.classList.remove("error");
    //         } else {
    //             setCpfIsValid(false);
    //             const input = document.getElementById('cpf');
    //             input.classList.add("error");

    //             store.addNotification({
    //                 title: "Erro!",
    //                 message: `Informe um CPF válido!`,
    //                 type: "danger",
    //                 insert: "top",
    //                 container: "top-right",
    //                 animationIn: ["animated", "fadeIn"],
    //                 animationOut: ["animated", "fadeOut"],
    //                 dismiss: {
    //                     duration: 4000,
    //                     onScreen: true
    //                 }
    //             });
    //         }
    //     }
    // }


    
    const handleUser = () => {

        if (nomeFantasia && 
            razaoSocial && 
            cnpj && 
            // cpf && 
            telefone && 
            cep && 
            logradouro && 
            numero && 
            bairro && 
            cidade && 
            (estado || estado.value)
            // ie
            ) {

            const payload = {
                nome_fantasia: nomeFantasia,
                razao_social: razaoSocial,
                cnpj,
                // cpf,
                telefone: telefone,
                // telefone2: telefone2,
                cep,
                logradouro,
                numero,
                // complemento,
                bairro,
                cidade,
                estado: estado.value || estado,
                // ie,
            }
            if (props.type === 1) {
                //cadastrar novo
                console.log("Cadastrar: ", payload)
            } else if (props.type === 2) {
                //editar existente
                console.log("Editar: ", payload)
            }
        } else {
            store.addNotification({
                title: "Erro!",
                message: `Preencha todos os campos`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
        


    }

    


    

    useEffect(() => {
        const changeNomeFantasia = (value) => {
            setNomeFantasia(value);
        }
        const changeRazaoSocial = (value) => {
            setRazaoSocial(value);
        }
        const changeCnpj = (value) => {
            setCnpj(value)
        }
        // const changeCpf = (value) => {
        //     setCpf(value);
        //     const input = document.getElementById('cpf');
        //     input.classList.remove("error");
        // }
        const changeTelefone = (value) => {
            setTelefone(value);
        }
        // const changeTelefone2 = (value) => {
        //     setTelefone2(value);
        // }
        const changeCep = (value) => {
            setCep(value);
            const input = document.getElementById('cep');
            input.classList.remove("error");
        }
        const changeLogradouro = (value) => {
            setLogradouro(value);
        }
        const changeNumero = (value) => {
            setNumero(value);
        }
        // const changeComplemento = (value) => {
        //     setComplemento(value);
        // }
        const changeBairro = (value) => {
            setBairro(value);
        }
        const changeCidade = (value) => {
            setCidade(value);
        }
        const changeEstado = (value) => {
            setEstado(value);
        }
        // const changeIe = (value) => {
        //     setIe(value);
        // }

        const getCompany = async () => {
            try {
                const response = await api.get('/api/company');
                if (response.data) {
                    const company = response.data.data;
                    console.log(company)
                    changeNomeFantasia(company.nome_fantasia);
                    changeRazaoSocial(company.razao_social);
                    changeCnpj(company.cnpj);
                    // changeCpf(company.cpf);
                    changeTelefone(company.telefone);
                    // changeTelefone2(company.telefone2);
                    changeCep(company.cep);
                    changeLogradouro(company.endereco);
                    changeNumero(company.numero);
                    // changeComplemento(company.complemento);
                    changeBairro(company.bairro);
                    changeCidade(company.cidade);
                    // changeIe(company.ie);
                    changeEstado(company.estado);
                }
            } catch (error) {
                
            }
        }

        const initializeComponent = () => {
            setLoad(true);
            if (props.type === 2) {
                getCompany();
            }
        }

        if (load === false) {
            initializeComponent();
        }
    },[load, props.type])

    return (
        <>
            <EmpresaContainer>
                <CadContainer>
                    <Input 
                        id='nome_fantasia'
                        label='Nome fantasia'
                        placeholder='Nome fantasia'
                        value={nomeFantasia}
                        onChange={(e) => changeNomeFantasia(e.target.value)}
                    />
                    <Input 
                        id='razao_social'
                        label='Razão social'
                        placeholder='Razão social'
                        value={razaoSocial}
                        onChange={(e) => changeRazaoSocial(e.target.value)}
                    />
                    <Input 
                        id='cnpj'
                        label='CNPJ'
                        placeholder='CNPJ'
                        mask='99.999.999/9999-99'
                        value={cnpj}
                        onChange={(e) => changeCnpj(e.target.value)}
                    />
                    {/* <Input 
                        id='cpf'
                        label='CPF'
                        placeholder='CPF'
                        mask='999.999.999-99'
                        value={cpf}
                        onChange={(e) => changeCpf(e.target.value)}
                        onBlur={() => validaCpf()}
                    /> */}
                    <Input 
                        id='telefone'
                        label='Telefone'
                        placeholder='Telefone'
                        mask='(99) 9999-9999'
                        value={telefone}
                        onChange={(e) => changeTelefone(e.target.value)}
                    />
                    {/* <Input 
                        id='telefone2'
                        label='Telefone 2'
                        placeholder='Telefone 2'
                        mask='(99) 9999-9999'
                        value={telefone2}
                        onChange={(e) => changeTelefone2(e.target.value)}
                    /> */}
                    <Input 
                        id='cep'
                        label='CEP'
                        placeholder='CEP'
                        mask='99999-999'
                        value={cep}
                        onChange={(e) => changeCep(e.target.value)}
                        onBlur={() => validaCep()}
                    />
                    <Input 
                        id='logradouro'
                        label='Logradouro'
                        placeholder='Logradouro'
                        value={logradouro}
                        onChange={(e) => changeLogradouro(e.target.value)}
                    />
                    <Input 
                        id='numero'
                        label='Número'
                        placeholder='Número'
                        value={numero}
                        onChange={(e) => changeNumero(e.target.value)}
                    />
                    {/* <Input 
                        id='complemento'
                        label='Complemento'
                        placeholder='Complemento'
                        value={complemento}
                        onChange={(e) => changeComplemento(e.target.value)}
                    /> */}
                    <Input 
                        id='bairro'
                        label='Bairro'
                        placeholder='Bairro'
                        value={bairro}
                        onChange={(e) => changeBairro(e.target.value)}
                    />
                    <Input 
                        id='cidade'
                        label='Cidade'
                        placeholder='Cidade'
                        value={cidade}
                        onChange={(e) => changeCidade(e.target.value)}
                    />
                    {/* <Input 
                        id='ie'
                        label='Inscrição Estadual'
                        placeholder='Inscrição Estadual'
                        value={ie}
                        onChange={(e) => changeIe(e.target.value)}
                    /> */}
                    <Select 
                        id='estado'
                        label='Estado'
                        placeholder='Estado'
                        estado={true}
                        value={estado}
                        onChange={(e) => changeEstado(e)}
                        options={estados}
                    />


                    <Button onClick={() => handleUser()}>
                        {props.type === 1 ? 'Cadastrar' : 'Salvar'}
                    </Button>

                </CadContainer>
            </EmpresaContainer>
        </>
    )
}
export default EmpresaForm;
