import styled from "styled-components";

export const NavBarContainer = styled.nav`
  display: ${props => (props.hide ? "none" : "flex")};
  height: 100vh;
  width: ${props => (props.isOpen ? "250px" : "0")};
  position: ${props => (props.isMobile ? "absolute" : "sticky")};
  position: ${props => (props.isMobile && props.isOpen ? "fixed" : "")};
  top: 0;
  left: 0;
  background: #1d2129;
  transition: all 0.3s;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  color: #dfe4eb;
  z-index: 999;
  overflow: ${props => (props.isMobile ? "" : "auto")};

    ::-webkit-scrollbar{ 
      /* 1 */ 
      width: 1px;
    }

    .simplebar-content{
      background: #1d2129;
      height: 100%;
      
    }

    .menu-flex{
        width: 100%;
        // width: 210px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: auto;
        margin-top: ${props => (props.isMobile ? '80px' : '0px')};

        ::-webkit-scrollbar{ 
          /* 1 */ 
          width: 1px;
        }

        .menu-icons{
            width: 92%;
            // height: 100%;
            overflow-x: none;  

        }


        
        .version{
            display: ${props => (props.isOpen ? "" : "none")};
            width: 100%;
            margin: 0 auto;
            font-family: Lato;
            font-size: 11px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #586377;
            bottom: 15px;
            p{
              text-align: center;
              label{
                color: #589bff;
                &:hover{
                  cursor: pointer;
                }
              }
            }
        }
    }


    .hamburger {
      position: relative;
      z-index: 1;
      margin-left: auto;
      width: auto;
      height: ${props => (props.isOpen ? "0px" : "46px")};
      top: 0;
      left: 0;
      padding-left: 15px;
      padding-top: 5px;
      display: flex;
      align-items: center;
      img {
        display: ${props => (props.isOpen ? "none" : "flex")};
        cursor: pointer;
      }
    }


    .logo {
      position: ${props => (props.isMobile ? "absolute" : "relative")};
      margin-top: ${props => (props.isMobile ? "20px" : "28px")};
      margin-left: ${props => (props.isMobile && props.isOpen ? "-135px" : "")};
      padding-left: ${props => (props.isMobile && props.isOpen ? "" : "10px")};
      left: ${props => (props.isMobile ? "50%" : "")};
      left :${props => (props.isOpen ? "":"50%")};
      width: ${props => (props.isMobile ? (props.isOpen ? '260px' : "100vw") : '100%')};
      /* width: ${props => (props.isMobile ? "100vw" : "")};
      width :${props => (props.isOpen ? "100%":"100vW")}; */
      transition: all 0.3s;

      /* 
      right: ${props => (props.isMobile ? "0" : "")};
      margin: ${props => (props.isMobile ? "0 auto" : "")};
      margin-bottom: ${props => (props.isMobile ? "" : "28px")}; 
      */

      /* margin-top: 28px; */
      padding-bottom: 20px;
      border-bottom: ${props => (props.isMobile && !props.isOpen ? "none" : "1px solid #303848")};
      /* width: 100%; */
      display: flex;
      align-items: center;
      /* background-color: red; */
      a {
        padding-left: ${props => (props.isMobile ? "0px" : "15px")};
        height: 33px;
        display: ${props => (props.isOpen ? "block" : "block")};
        /* background-color: green; */
        margin:  ${props => (props.isMobile ? "auto" : "")};
        img {
          /* width: auto; */
          max-height: 33px;
        }
      }
    }
`;

export const NavItem = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  /* background-color: red; */
  margin-top: 5px;


  #hover-menu{
    width: 100%;
    /* background-color: red; */
    margin: auto;
    border-radius: 5px;
    height: 40px;
    margin-top: 5px;
    background-color: ${props => (props.hover ? "#292e38" : "")};
    :hover{
      background-color: #292e38;
    }

    :target{
      background-color: #292e38;
    }
    
  }
  
  .position{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* flex-direction: row;
    align-items: center; */
    /* display: flex;
    justify-content: space-evenly; 
    align-items: center; 
    position: relative; */

    .Oval {
      margin-left: -11px;
      z-index: 20000;
      position: relative;
      margin-top: -25px;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: #ff5252;
      font-size: 11px;
    }

    
    p{
      width: 175px;
      font-size: 10px;
      margin-left: 8px;
      color: ${props => (props.hover ? "#FFF" : "")};

    }

    i{
      /* margin-top: -3px; */
      margin-right: ${props => (props.isMobile ? "-45px" : "-16px")};
      /* position: ${props => (props.isMobile ? "absolute" : "")};
      right: ${props => (props.isMobile ? "-15px" : "")};
      top: ${props => (props.isMobile ? "32px" : "")}; */

    }
  
    img{
      /* position: relative;
      margin-top: -23px;*/
      margin-left: -5px; 
      /* margin: auto auto auto 4px; */
      /* margin-top: 9px; */
    }
  }

 

  &:hover {
    
    p{
      color: #FFF;
    }
  }


`;

export const Collapse = styled.div`
  width: 175px;
  display: ${props => (props.show ? "flex" : "none")};
  align-items: center;
  transition: 0.3s all;
  padding-left: 10px;
  p {
    margin: 0px;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #8a92a2;
  }
`;

export const Dropdown = styled.div`
  width: ${props => (props.isOpen ? "auto" : "0")};
  display: flex;
  /* background: #21262f; */
  width: 100%;
  flex-direction: column;
  transition: all 0.3s;
  font-size: 11px;
  color: #586377;
  font-weight: bold;
  /* background-color: ${props => (props.hover ? "#292e38" : "null")}; */
 
    #usua{
      // background-color: white;
      background-color: ${props => (props.hover ? "#292e38" : "null")};
    }
  
  div {
    transition: all 0.3s;
    width: 83%;
    display: ${props => (props.isOpen ? "block" : "none")};
    cursor: pointer;
    margin-top: 5px;
    margin-left: 44px;
    font-size: 10px;
    letter-spacing: 1px;
    &+div{
      /* margin-top: -13px; */
    }

    

    .itemDropdown{
      width: 98%;
      border-radius: 5px;
      margin-left: -8px;
      padding: 10px 3px 10px 6px;
      cursor: pointer;
      background-color: ${props => (props.hover ? "#292e38" : "none")};

    }

    &:hover {
      .itemDropdown{
        color: #FFF;
        background-color: #292e38;
      }
    }

    #filiais{
      background-color: ${props => (props.hoverFilial ? "#292e38" : "none")};
      color: ${props => (props.hoverFilial ? "#FFF" : "none")};
    }
    #historico{
      background-color: ${props => (props.hoverHistorico ? "#292e38" : "transparent")};
      color: ${props => (props.hoverHistorico ? "#FFF" : "none")};
    }

    #hoverUsuario{
      background-color: ${props => (props.hoverUsuario ? "#292e38" : "none")};
      color: ${props => (props.hoverUsuario ? "#FFF" : "none")};
    }

    #local{
      background-color: ${props => (props.hoverLocal ? "#292e38" : "none")};
      color: ${props => (props.hoverLocal ? "#FFF" : "none")};
    }

    #responsavel{
      background-color: ${props => (props.hoverResponsavel ? "#292e38" : "none")};
      color: ${props => (props.hoverResponsavel ? "#FFF" : "none")};
    }

    #art{
      background-color: ${props => (props.hoverArt ? "#292e38" : "none")};
      color: ${props => (props.hoverArt ? "#FFF" : "none")};
    }

    #produtores{
      background-color: ${props => (props.hoverProdutores ? "#292e38" : "none")};
      color: ${props => (props.hoverProdutores ? "#FFF" : "none")};
    }

    #fazendas{
      background-color: ${props => (props.hoverFazendas ? "#292e38" : "none")};
      color: ${props => (props.hoverFazendas ? "#FFF" : "none")};
    }

    #relatorios{
      /* background-color: ${props => (props.hoverFazendas ? "#292e38" : "none")}; */
      color: ${props => (props.hoverRelatorios ? "#FFF" : "none")};
    }

  }
`;

export const UserContainer = styled.div`

  .profile-picture{

    position: ${props => (props.isMobile ? "absolute" : "absolute")};
    height: 35px;
    width: 35px;
    background: #303848;
    right: 3%;
    top: ${props => (props.isMobile ? "5px" : "20px")};
    display: ${props => (props.hide ? "none" : "flex")};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 999;
  }

`;
