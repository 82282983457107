import React from 'react';
import { ContainerLoading, Load } from './style';

const Loading = ({ ...props }) => {

    return(
        <ContainerLoading 
            height={props.heightContainer}
        >
            <Load
                width={props.width}
                height={props.height}
            />
        </ContainerLoading>
    )
}

export default Loading;
