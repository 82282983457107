import styled from "styled-components";

export const Dashboard = styled.div`
    width: 100%;
    height: auto;
    padding: 30px;
    padding-right: 60px;
    padding-left: 60px;
    /* background-color: green; */
    display: flex;
    flex: 3;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};

    @media only screen and (max-width: 1217px) {
        padding-right: 30px;
        padding-left: 30px;
    }
`;

export const Grid = styled.div`
    width: ${props => (props.width ? props.width : '100%')};
    height: ${props => (props.height ? props.height : '160px')};
    margin: 0px;
    margin-top: ${props => (props.marginTop && props.marginTop !== '-15px' ? props.marginTop : '0px')};
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0px')};
    display: flex;
    flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'row')};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'left')};
    align-items: ${props => (props.alignItems ? props.alignItems : 'initial')};
    flex-wrap: wrap;
    canvas {
        /* background-color: green; */
        width:  ${props => (props.canvasWidth ? props.canvasWidth : '0px')} !important;
        height: ${props => (props.canvasHeigth ? props.canvasHeigth : '0px')} !important;
        /* height: 100% !important; */
        /* display: flex !important; */
    }

    @media only screen and (max-width: 1920px) {
        height: ${props => props.isResponsive && props.isResponsiveWhenWidth === '1920px' ? '250px' : (props.height ? props.height : '160px')};
    }
    @media only screen and (max-width: 1217px) {
        margin-top: ${props => props.marginTop ? props.marginTop : '15px'};
        width: ${props => props.isResponsive && props.isResponsiveWhenWidth === '1217px' ? '100%' : (props.width ? props.width : '160px')};
    }
    @media only screen and (max-width: 910px) {
        width: 100%;
        height:  ${props => props.isResponsive && props.isResponsiveHeight ? props.isResponsiveHeight : (props.height ? props.height : 'auto')};
    }
    @media only screen and (max-width: 630px) {
        height: ${props => props.isResponsive && props.isResponsiveHeight ? '500px' : (props.height ? props.height : '160px')};
    }

`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'column')};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'left')};
    align-items: initial;
    margin-bottom: 10px;

    @media only screen and (max-width: 910px) {
        flex-wrap: wrap;
        div {
            width: 100%;
        }

        h4 {
            padding-top: 0% !important;
            padding-bottom: 46% !important;
            margin-top: 100px;
            align-items: center;
        }
    }
    @media only screen and (max-width: 550px) {

        h4 {
            padding-top: 50% !important;
            padding-bottom: 50% !important;
            margin-top: 60px;
            align-items: center;
        }
    }
`;

export const TitleCard = styled.h2`
    font-family: Lato, sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #8a92a2;
`;

export const Label = styled.label`
    width: 100%;
    font-family: Lato, sans-serif;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bac1cf;
    text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
    margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0px')};
`;

export const Title = styled.label`
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303848;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
    text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
`;

export const Text = styled.p`
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303848;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
`;

export const Estado = styled.p`
    font-family: Lato, sans-serif;
    width: 40px;
    height: 25px;
    border-radius: 5px;
    background-color: #f3f3f5;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #ff5252;
    margin-right: 15px;
    padding-top: 5px;
    margin-top: 5px;
`;

export const SecretValue = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : 'auto'};
    filter: ${props => props.isHide ? 'blur(0.22rem)' : 'blur(0)'};
    transition: all 0.5s ease-out;
`;

