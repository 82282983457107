import React, { useState, useEffect } from 'react';
import { 
    withRouter, 
    Link 
} from 'react-router-dom';
import Logo from '../../assets/logo.png';
import Button from '../../components/button';
import Input from '../../components/input';
import { store } from 'react-notifications-component';

import { LoginWrapper } from './styles';
import { login, logout } from '../../services/auth';

import api from '../../services/api';

import Modal from '../../components/modal';


const Login = ({ ...props }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [typePassword, setTypePassword] = useState('password');

    const [showModal, setShowModal] = useState(false);

    const handleSignIn = async (e) => {
        e.preventDefault();
        if (email && password) {
            try {
                const response = await api.post('/login/dashboard', { username: email, password });
                login(response.data.data);
                props.history.push('/');
            } catch (err) {

                store.addNotification({
                    title: 'Erro!',
                    message: `Houve um problema com o login, verifique suas credenciais.`,
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } else {
            store.addNotification({
                title: 'Erro!',
                message: `Houve um problema com o login, verifique suas credenciais.`,
                type: 'danger',
                insert: 'top',
                container: 'top-right',
                animationIn: ['animated', 'fadeIn'],
                animationOut: ['animated', 'fadeOut'],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const changeEmail = (value) => {
        setEmail(value);
    }

    const changePassword = (value) => {
        setPassword(value);
    }

    const alterTypePassword = () => {
        if (typePassword === 'password') {
            setTypePassword('text');
        } else {
            setTypePassword('password');
        }
    }

    const handlePassword = async () => {
        try{
            const payload = {
                username: email
            }
            const response = await api.post("/recover", payload);
            
            store.addNotification({
                title: "Sucesso!",
                message: `${response.data.data[0].informacao}`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            setShowModal(false);
        }catch (err) {
            const errors = await err.response.data.errors;
            
            store.addNotification({
                title: "Erro!",
                message: `${errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    useEffect(() => {
        logout();
    })

    return(
        <LoginWrapper>
            <div className='total'>
                <div className='logo'>
                    <a href='http://www.agroreceita.com.br' target='_blank' rel='noopener noreferrer'>
                        <img src={Logo} alt='logo' />
                    </a>
                    <label className='title'>Olá, seja bem vindo(a)!</label>
                    <label className='subtitle'>Preencha os campos ao lado para entrar</label>
                </div>

                <div className='form'>
                    <form onSubmit={handleSignIn}>
                        <div className='email'>
                            <Input
                                label='E-mail'
                                name='email'
                                value={email}
                                placeholder='Digite seu e-mail'
                                type='email'
                                onChange={e => changeEmail(e.target.value)}
                            />
                        </div>
                        <div className='senha'>
                            <Input
                                name='password'
                                label='Senha'
                                value={password}
                                placeholder='Digite a sua senha'
                                type={typePassword}
                                onChange={e => changePassword(e.target.value)}
                                
                            />
                            <i class='fa fa-eye' onClick={() => alterTypePassword()}></i>
                        </div>
                        <div className='teste'>
                            <Link 
                                className='link' 
                                onClick={() => setShowModal(true)} 
                                to='#'
                            >Esqueceu sua senha?</Link>

                            <Button style={{outline:0}} className='button' color='#00b43f' type='submit'>
                                Entrar 
                            </Button>
                        </div>
                    </form>
                </div>
            </div>

            {showModal ? (
                <Modal width='420px' title='Esqueceu a senha?' subtitle='Digite o e-mail ou usuário cadastrado para alterá-la.' close={() => setShowModal(false)}>
                    <Input
                        label=' '
                        name='email'
                        value={email}
                        placeholder='Digite seu e-mail'
                        type='email'
                        onChange={e => changeEmail(e.target.value)}
                    />

                    <Button style={{outline:0}} className='button' color='#00b43f' onClick={() => handlePassword()}>
                        Redefinir senha 
                    </Button>
                </Modal>
            ) : (null)}
        </LoginWrapper>
    );
}

export default withRouter(Login);
