import React from 'react';
import Header from '../../components/header';
import { 
    withRouter,
} from 'react-router-dom';
import {
    RelatoriosContainer,
    Card,
} from './styles';
import RelatorioGeral from '../../assets/RELATORIO_GERAL.svg'
import Analytics from '../../assets/undraw_Analytics.svg'
// import Growth from '../../assets/undraw_Growth_analytics.svg'
// import Investing from '../../assets/undraw_Investing.svg'
// import Metrics from '../../assets/undraw_Metrics.svg'
// import Segment from '../../assets/undraw_Segment_analysis.svg'
// import Segmentation from '../../assets/undraw_Segmentation.svg'
// import Visual from '../../assets/undraw_visual_data.svg'
import api from '../../services/api';


const generalReport = async () => {
    try {
        let obj = JSON.parse(localStorage.getItem("@userinfo"));
        if(obj){   
            const response = await api.get(`/dev/general_report/${obj.id}`);
            if (response) {
                const link = response.data.data.link;
                window.open(link, '_blank');
            }
        }
    } catch (error) {
        
    }
} 

const userRequestsReport = async () => {
    try {
        let obj = JSON.parse(localStorage.getItem("@userinfo"));
        if(obj){   
            const response = await api.get(`/dev/user_requests_report/${obj.id}`);
            if (response) {
                const link = response.data.data.link;
                window.open(link, '_blank');
            }
        }
    } catch (error) {
        
    }
} 

const Relatorios = ({ ...props }) => {

    return(
        <>
            <Header title="Relatórios" align='center' />
            <RelatoriosContainer>
                <Card
                    onClick={() => generalReport()}
                >
                    <img alt='' src={RelatorioGeral}/>
                    <h2>Relatório Geral de usuários</h2>
                </Card>
                <Card
                    onClick={() => userRequestsReport()}
                >
                    <img alt='' src={Analytics}/>
                    <h2>Requisições por usuário</h2>
                </Card>
                
                {/* <Card>
                    <img alt='' src={Growth}/>
                    <h2>Relatório Geral</h2>
                </Card>
                <Card>
                    <img alt='' src={Investing}/>
                    <h2>Relatório Geral</h2>
                </Card>
                <Card>
                    <img alt='' src={Metrics}/>
                    <h2>Relatório Geral</h2>
                </Card>
                <Card>
                    <img alt='' src={Segment}/>
                    <h2>Relatório Geral</h2>
                </Card>
                <Card>
                    <img alt='' src={Segmentation}/>
                    <h2>Relatório Geral</h2>
                </Card>
                <Card>
                    <img alt='' src={Visual}/>
                    <h2>Relatório Geral</h2>
                </Card> */}
                
            </RelatoriosContainer>
        </>
    )
}
export default withRouter(Relatorios);
