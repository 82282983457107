import styled from "styled-components";

export const LoginWrapper = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');

  display: flex;
  background-color: #1d2129;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 650px;
  position: relative;
  flex-direction: column;
  margin: auto;

  .total{
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    .logo {
      width: 30%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      top: 20px;

      img{
        width: 150px;
        height: 33px;
      }

      .title{
        margin-top: 40px;
        width: 307px;
        height: 34px;
        font-family: Lato, sans-serif;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: #fff;
      }
      .subtitle{
        width: 220px;
        height: 17px;
        font-family: Lato, sans-serif;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8a92a2;
      }
    }
    .form{
      width: 330px;
      height: 410px;
      background-color: #f8f8f9;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      form{
        .error {
          width: 300px;
          color: #ff3333;
          margin-bottom: 20px;
          margin-top: -37px;

        }
        margin-top: 0px;
        width:  270px;
        .senha{
          display: flex;
          flex-direction: row;
          i{
            position: absolute;
            margin-left: 236px;
            margin-top: 69px;
            opacity: 0.6;
          }
          i:hover{
            opacity: 2;
            cursor: pointer;
          }
        }
        .teste{
          height: 126px;
          display: flex;
          justify-content: space-around;
          flex-direction: column;
          align-items: center;
          .link{
            width: 130px;
            height: 15px;
            font-family: Lato, sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #589bff;
          }
          .button{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;
            border: 1px solid #00b43f;
            border-radius: 30px;
            color: #fff;
            font-weight: bold;
            font-size: 11px;
            background: #00b43f;
            margin: 0px;
            transition: all 0.3s;

            &:hover {
              cursor: pointer;
              background: #00b43f;
              color: #FFF;
              box-shadow: 0px 0px 6px 1px #00b43f;
            }
            &:disabled {
              cursor: auto;
              opacity: 0.5;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
  .foot{
    width: 270px;
    margin-top: 5%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    p{
      width: 118px;
      height: 17px;
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #8a92a2;
    }
    .link{
      margin-top: -7px;
      button{
        width: 175px;
      }
    }
  }



  display: flex;
  background-color: #1d2129;
  align-items: center;
  justify-content: center;
  min-height: 650px;
  position: relative;
  flex-direction: column;
  margin: auto;
  
  .telaTo, sans-serifda{
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .total{
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      width: 100%;
      margin-top: -50px;
      height: 160px;
      .logo {
        width: 30%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        top: 20px;
        img{
          width: 150px;
          height: 33px;
        }
        .title{
          margin-top: 40px;
          width: 307px;
          height: 34px;
          font-family: Lato, sans-serif;
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--white);
        }
        .subtitle{
          width: 196px;
          height: 17px;
          font-family: Lato, sans-serif;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #8a92a2;
        }
      }

    }
    .doisForms{
      display: flex;
      justify-content: space-around;
      flex-direction: row-reverse;
      
      width: 100%;

      .formCriarConta{
        width: 330px;
        height: 356px;
        margin-top: 64px;
        background-color: #f8f8f9;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        form{
          p{
            margin-left: 50px;
            font-family: Lato, sans-serif;
            font-size: 18px;
            // font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #8a92a2;
          }
          .error {
            width: 300px;
            color: #ff3333;
            margin-bottom: 20px;
            margin-top: -37px;

          }
          margin-top: 0px;
          width:  270px;
          .senha{
            display: flex;
            flex-direction: row;
            i{
              position: absolute;
              margin-left: 236px;
              margin-top: 69px;
              opacity: 0.6;
            }
            i:hover{
              opacity: 2;
            }
          }
          .teste{
            height: 126px;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            .link{
              width: 130px;
              height: 15px;
              font-family: Lato, sans-serif;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #589bff;
            }
            .button{
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 50px;
              border: 1px solid #00b43f;
              border-radius: 30px;
              color: #fff;
              font-weight: bold;
              font-size: 11px;
              background: #00b43f;
              margin: 0px;
              transition: all 0.3s;

              &:hover {
                cursor: pointer;
                background: #00b43f;
                color: #FFF;
                box-shadow: 0px 0px 6px 1px #00b43f;
              }
              &:disabled {
                cursor: auto;
                opacity: 0.5;
              }
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
            
      .form{
        width: 330px;
        height: 420px;
        background-color: #f8f8f9;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        form{
          p{
            margin-left: 50px;
            font-family: Lato, sans-serif;
            font-size: 18px;
            // font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #8a92a2;
          }
          .error {
            width: 300px;
            color: #ff3333;
            margin-bottom: 20px;
            margin-top: -37px;

          }
          margin-top: 0px;
          width:  270px;
          .senha{
            display: flex;
            flex-direction: row;
            i{
              position: absolute;
              margin-left: 236px;
              margin-top: 69px;
              opacity: 0.6;
            }
            i:hover{
              opacity: 2;
            }
          }
          .teste{
            height: 126px;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            .link{
              width: 130px;
              height: 15px;
              font-family: Lato, sans-serif;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #589bff;
            }
            .button{
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 50px;
              border: 1px solid #00b43f;
              border-radius: 30px;
              color: #fff;
              font-weight: bold;
              font-size: 11px;
              background: #00b43f;
              margin: 0px;
              transition: all 0.3s;

              &:hover {
                cursor: pointer;
                background: #00b43f;
                color: #FFF;
                box-shadow: 0px 0px 6px 1px #00b43f;
              }
              &:disabled {
                cursor: auto;
                opacity: 0.5;
              }
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
  .foot{
    width: 270px;
    margin-top: 5%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    p{
      width: 118px;
      height: 17px;
      font-family: Lato, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #8a92a2;
    }
    .link{
      margin-top: -7px;
      text-decoration: none;

      button{
        width: 175px;
        padding: 8px;
        font-size: 11px;
        border-radius: 2rem;
        background-color: transparent;
        border: 1px solid #FFF;
        color: #FFF;
        font-weight: bold;
      }
    }
  }


  
    

  @media only screen and (max-width: 768px) {
    display: flex;
    background-color: #1d2129;
    align-items: center;
    justify-content: center;
    min-height: 650px;
    position: relative;
    flex-direction: column;
    height: auto;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;

    .total{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      .logo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: -40px;
        /* margin-bottom: 20px; */
        img{
          width: 119px;
          height: 26px;
          margin-top: -40px;

        }
        .title{
          width: 206px;
          height: 24px;
          font-family: Lato, sans-serif;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #FFF;
        }
        .subtitle{
          width: 260px;
          height: 17px;
          font-family: Lato, sans-serif;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #8a92a2;
        }
      }
      .form{
        width: 315px;
        height: 340px;
        background-color: #1d2129;
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        form{
          .error {
            width: 300px;
            color: #ff3333;
          }
          margin-top: -25px;
          width:  270px;
          input{
            background-color: #292e38;
          }
          .teste{
            height: 100px;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            .link{
              width: 130px;
              height: 15px;
              font-family: Lato, sans-serif;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #589bff;
            }
            .button{
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 50px;
              border: 1px solid #00b43f;
              border-radius: 30px;
              color: #fff;
              font-weight: bold;
              font-size: 11px;
              background: #00b43f;
              margin: 0px;
              transition: all 0.3s;

              &:hover {
                cursor: pointer;
                background: #00b43f;
                color: #FFF;
                box-shadow: 0px 0px 6px 1px #00b43f;
              }
              &:disabled {
                cursor: auto;
                opacity: 0.5;
              }
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }

    .telaToda{
      display: flex;
      flex-direction: column;

      .total{
        margin-top: 40px;
      }

      .doisForms{
        display: flex;
        flex-direction:column;
        align-items: center;

        

      }
    }




    .foot{
      width: 270px;
      margin-top: 5%;
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      /* background-color: red; */
      /* p{
        width: 118px;
        height: 17px;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #8a92a2;
      } */
      .link{
        /* margin-top: -7px; */
        width: 100%;
        /* height: 35px; */
        /* border-radius: 30px; */

        button{
          /* width: 130px;
          height: 35px;
          border-radius: 30px;
          border: solid 2px #2fbb69;
          padding: 3px; */
          width: 100%;
          margin: auto;
        }
      }
    }
  }


  @media only screen and (max-width: 320px) {
      .telaToda{
        .doisForms{
          .form{
            width: 87%;
            form{
              width: 87%;

              .senha{
                i{
                  margin-left: 199px;
                  margin-top: 66px;
                }
              }
              
            }
          }

          .formCriarConta{
            width: 87%;
            form{
              width: 87%;

              .title{
                margin-left: 29px;
              }
            }
          }
        }
      }
  }
`;
