import { getUserInfo } from "../services/auth";

export const formatClientsForTable = (values) => {
    //COLUNAS: ['Nome fantasia', 'Razão social', 'Telefone', 'Cidade', 'Estado']
    let data = [];
    values.map((c, i) => {
        if (c.id !== c.parent_id) {
            data[i] = {
                id: c.id,
                status: c.status,
                values: [
                    c.nome_fantasia,
                    c.id,
                    c.razao_social,
                    c.telefone,
                    c.cidade,
                    c.estado
                ]
            }
        }
        return true;
    });
    return data;
}

export const formatClientsForSelect = (values) => {

    const user = getUserInfo();
    let index = 0;
    
    let data = [];

    values.forEach((c) => {
        if (c.id !== user.agro_subsidiary_id && c.status === 1) {
            data[index] = {
                value: c.id,
                label: c.nome_fantasia
            }
            index++;
        }
    })

    console.log(data);

    return data;
}

export const formatUsersForTable = (values) => {
    //COLUNAS: ['Nome', 'Cliente (filial.nome_fantasia)', 'CPF', 'Cidade', 'Estado']
    let data = [];
    values.map((u, i) => (
        data[i] = {
            id: u.id,
            filialId: u.filial.id,
            status: u.status,
            values: [
                u.nome,
                u.id,
                u.filial.nome_fantasia,
                u.cpf,
                u.filial.cidade,
                u.filial.estado
            ]
        }
    ));
    return data;
}

export const parseDateDMY = (value) => {
    let date = '';
    const countDate = value.length;

    if (countDate === 19) {
        const splitDate = value.split('');

        if (splitDate[10] === 'T') {
            if (splitDate[4] === '-') {
                if (splitDate[7] === '-') {
                    if (splitDate[13] === ':') {
                        if (splitDate[16] === ':') {
                            // console.log("IS DATETIME")
                            date = `${splitDate[8]}${splitDate[9]}/${splitDate[5]}${splitDate[6]}/${splitDate[0]}${splitDate[1]}${splitDate[2]}${splitDate[3]}`
                        }
                    }
                }
            }
        }
    } else if (countDate === 10) {
        const splitDate = value.split('');
        if (splitDate[4] === '-' || splitDate[4] === '/') {
            if (splitDate[7] === '-' || splitDate[7] === '/') {
                date = `${splitDate[8]}${splitDate[9]}/${splitDate[5]}${splitDate[6]}/${splitDate[0]}${splitDate[1]}${splitDate[2]}${splitDate[3]}`;
            }
        }
    }

    return date;

}
