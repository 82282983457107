import React from "react";

import { UpperContainer } from "./styles";
// import { Container } from "react-grid-system";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";


const GenericUpper = ({ ...props }) => (
  <UpperContainer {...props}>
    {props.showLogo === true && props.showFaq === true ? (<>
    <div className="iconsLogo">
      <div className="logo">
          <a href="http://www.agroreceita.com.br" >
              <img src={Logo} alt="logo" />
          </a>
      </div>
      <div className="faq">
          <a href="https://www.agroreceita.com.br/ajuda/" target="_blank" rel="noopener noreferrer">
              FAQ
          </a>
      </div>
    </div>
    </>) : (null)}

    <div id="containerId">
      {props.logo === true ? (<>
        <Link to="/">
            <img src={Logo} alt="logo" style={{ width: "150px", marginLeft: "-28px", marginTop: "-55px" }}/>
        </Link>
      </>):(null)}
      <h1>{props.title}</h1>
      <small>{props.subtitle}</small>
    </div>
  </UpperContainer>
);

export default GenericUpper;
