import styled from 'styled-components';

export const TableContainer = styled.div`
    width: 100%;
    height: auto;
    background-color: #FFF;
    box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
    border: 1px solid #FFF;
    border-radius: 5px;

    table {
        width: 100%;
        padding: 5px 10px;
        /* background-color: green; */
    }

    table tr {
        /* background-color: blue; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    table tr th {
        /* background-color: red; */
        text-align: left;
        padding-left: 15px;
        font-family: Lato, sans-serif;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #8a92a2;

        .selectedColumn {
            position: absolute;
            display: initial;
            margin-top: 1px;
            margin-left: 2px;
            /* margin-top: 1px; */
        }
        
        .noSelectedColumn {
            position: absolute;
            display: none;
            margin-top: 1px;
            margin-left: 2px;
            /* margin-top: 1px; */
        }


        /* width:  */
        &:hover {
            cursor: pointer;
            color: #599bff;

            .noSelectedColumn {
                display: initial;
            }
        }
    }
    
    table tr td {
        text-align: left;
        padding-left: 15px;
        
    }

    table .title {
        /* background-color: orange; */
        padding: 15px 0px 15px 0px;
        border-bottom: 1px solid rgba(138,146,162, 0.3);
    }

    table .row {
        /* background-color: yellow; */
        padding: 10px 0px 10px 0px;
        margin-top: 2px;
        margin-bottom: 2px;
        border: 1px solid rgba(29,33,41, 0);
        border-radius: 8px;

        td:first-child {
            font-family: Lato, sans-serif;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #303848;
        }

        &:hover {
            /* background-color: grey; */
            border: 1px solid rgba(29,33,41, 0.1);
            background-color: rgba(29,33,41, 0.02);
            box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.02);

            transition: all 300ms;
            cursor: pointer;
        }
    }
`;