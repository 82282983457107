import api from "./api";

export const TOKEN_KEY = "@agroReceita-Token";
export const KEY = "@key";
export const EXPIRATION = "@expiration";
export const USER = "@userinfo";
export const TERM = "@terms";
export const PLAN_NAME = "@plan_name";
export const screen = "@screen";
export const aviso = "@aviso";
export const link = "@link";
export const USER_VALIDADE = "@validade";
export const titulo = "@titulo";
export const subtitulo = "@subtitulo";
export const open = "@open";
export const USER_ATIVO ="@user_ativo";  //verificar no login se usuário já está logado



export const isAuthenticated = () => {
    let expiration = localStorage.getItem(EXPIRATION);
    let now = new Date();

    if (expiration < now) {
      return false;
    }
    return true;
};

export const login = userData => {
    let now = new Date();

    console.log(userData);

    localStorage.setItem(TOKEN_KEY, userData.token);
    localStorage.setItem(KEY, userData.apiKey);
    localStorage.setItem(EXPIRATION, now.setHours(now.getHours() + 10));
    localStorage.setItem(USER, JSON.stringify(userData.user));
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getApiKey = () => localStorage.getItem(KEY);
export const getUserInfo = () => JSON.parse(localStorage.getItem(USER));

export const logout = async () => {
    // console.log("Tentou logout");
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(KEY);
    localStorage.removeItem(EXPIRATION);
    
    const a = localStorage.getItem(USER);
    let obj = JSON.parse(a);
    localStorage.removeItem(USER);
    
    if(obj){
      try{
        await api.post(`/logout/${obj.id}?action=ok`);  
      }catch(err){
        await api.post(`/logout/${obj.id}`);  
      }
      // return response.data.data
    }
};
  
export const clearSession = async () =>{
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(EXPIRATION);
    localStorage.removeItem(USER_VALIDADE);
    localStorage.removeItem(USER_ATIVO);
    localStorage.removeItem(aviso);
    localStorage.removeItem(link);
    localStorage.removeItem(titulo);
    localStorage.removeItem(open);
    localStorage.removeItem(subtitulo);
    localStorage.removeItem(USER);
}
  