import React, { useEffect, useRef } from "react";
import Lupa from '../../assets/icons/icons8-search.png';
import InputMask from "inputmask";

// import { InputContainer } from "./styles";

const Input = ({ ...props }) => {

  const inputEl = useRef(null);
  
  useEffect(() => {
    let input = inputEl.current;
    let im = new InputMask(props.mask, { showMaskOnHover: false });
    im.mask(input);

  })

  return (
    <div 
      className="inputContainer"
      style={{
        width: '100%',
        maxWidth: '500px',
        margin: 'auto',
      }}
    >
      <input  
        style={{
          // marginLeft: '200px',
        }}
        ref={inputEl}
        {...props} 
      ></input> 
      <img 
        className="unidadeDose"
        style={{
          position: 'absolute',
          marginLeft: '-50px',
          marginTop: '3px',
          width: '25px',
          paddingTop: '10px',
          paddingBottom: '10px',
          // paddingLeft: '10px',
          // paddingRight: '10px',
          // right: '17%',
        }}
        src={Lupa}
        onClick={() => props.onClickSearch()}
        alt=''
      /> 
    </div>
  );
}

export default Input;
