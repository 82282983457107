import styled from 'styled-components';

export const EmpresaContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};

    button {
        /* position: absolute; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 400px;
        height: 50px;
        border: 1px solid #00b43f;
        border-radius: 30px;
        color: #fff;
        font-weight: bold;
        font-size: 11px;
        background: #00b43f;
        margin-top: 10px;
        transition: all 0.3s;

        &:hover {
        cursor: pointer;
        background: #00b43f;
        color: #FFF;
        box-shadow: 0px 0px 6px 1px #00b43f;
        }
        &:disabled {
        cursor: auto;
        opacity: 0.5;
        }
        &:focus {
        outline: none;
        }
    }
`;

export const SearchAndAdd = styled.div`
    width: 100%;
    height: 50px;
    margin-top: -25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: row-reverse;
    /* background-color: blue; */
    img {
        &:hover{
        cursor: pointer;
        }
    }

    button {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 50px;
        border: 3px solid #FFF;
        border-radius: 30px;
        color: #fff;
        font-weight: bold;
        font-size: 11px;
        background: #00b43f;
        margin: 0px;
        transition: all 0.3s;

        &:hover {
        cursor: pointer;
        background: #00b43f;
        color: #FFF;
        box-shadow: 0px 0px 6px 1px #00b43f;
        }
        &:disabled {
        cursor: auto;
        opacity: 0.5;
        }
        &:focus {
        outline: none;
        }
    }

`;

export const CadContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

`;
