import styled from "styled-components";

export const UpperContainer = styled.div`
  height: 185px;
  background: ${props => props.background || "#21262f"};
  background-size: cover;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  display: flex;

  .iconsLogo {
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logo{
      width: 200px;
      position: absolute;
      top: 20px;
      left: 20px;
      img{
          width: 150px;
      }
    }

    .faq{
      position: absolute;
      top: 25px;
      right: 50px;
      a{
          text-decoration: none;
          font-family: Lato, sans-serif;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #dfe4eb;
      }
    }
  }



  #containerId {
    width: 100%;
    text-align: ${props => props.align || "center"};
    padding-left: 95px;
    padding-right: 95px;
    h1 {
      font-family: Lato, sans-serif;
      color: #fff;
      font-weight: 900;
      font-size: 28px;
    }
    small {
      padding: 10px;
      font-size: 14px;
      margin-top: 20px;
      color: #808080;
    }
  }

  @media only screen and (max-width: 855px) {
    #containerId {
      text-align: center;
      padding-left: 0px;
      padding-right: 0px;
      h1 {
        font-family: Lato, sans-serif;
        color: #fff;
        font-weight: 900;
        font-size: 20px;
      }
      small {
        padding: 10px;
        font-size: 14px;
        margin-top: 20px;
        color: #808080;
      }
    }
  }

  @media only screen and (max-width: 350px) {
    #containerId {
      text-align: center;
      padding-left: 0px;
      padding-right: 0px;
      h1 {
        font-family: Lato, sans-serif;
        color: #fff;
        font-weight: 900;
        font-size: 15px;
      }
      small {
        padding: 10px;
        font-size: 13px;
        margin-top: 20px;
        color: #808080;
      }
    }
  }

  @media only screen and (max-width: 425px){
    height: 185px;
    background: ${props => props.background || "#21262f"};
    background-size: cover;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    display: flex;

    .logo{
      top: 20px;
      left: 0px;
      img{
          width: 150px;
      }
    }

    .faq{
      top: 25px;
      right: 35px;

    }
    div {
      /* width: 100%; */
      text-align: ${props => props.align || "center"};
      h1 {
        font-family: Lato, sans-serif;
        color: #fff;
        font-weight: 900;
        font-size: 20px;
      }
      small {
        padding: 10px;
        font-size: 12px;
        margin-top: 20px;
        color: #808080;
      }
    }
  }
`;
