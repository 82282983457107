import React from 'react';
import { withRouter } from 'react-router';
import Header from '../../components/header';
import {
    ClientesContainer,
    CadContainer,
} from './styles';
import Form from '../../components/forms/clientes'

const ClientesAdd = ({ ...props }) => {

    return (
        <>
            <Header title="Clientes" subtitle='Adicionar nova revenda/cooperativa' align='center'/>
            <ClientesContainer>
                <CadContainer>

                    <Form type={1} prop={props}/>

                </CadContainer>
            </ClientesContainer>
        </>
    )
}
export default withRouter(ClientesAdd);
