import styled from 'styled-components';

export const ContainerLoading = styled.div`
    width: 100%;
    height: ${props => (props.height ? props.height : '400px')} ;
    /* margin: auto; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const Load = styled.div`

    @-webkit-keyframes rotating /* Safari and Chrome */ {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }



    width: ${props => (props.width ? props.width : '75px')};
    height: ${props => (props.height ? props.height : '75px')};
    background-color: transparent;
    border-top: 1px solid #00b43f;
    border-bottom: 1px solid #00b43f;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-radius: 50%;
    -webkit-animation: rotating 0.7s linear infinite;
    -moz-animation: rotating 0.7s linear infinite;
    -ms-animation: rotating 0.7s linear infinite;
    -o-animation: rotating 0.7s linear infinite;
    animation: rotating 0.7s linear infinite;
`;