import React from 'react';
import Routes from './routes';
import Navbar from "../src/components/navbar";
import GlobalStyles, { Layout, Main } from "./styles/global";
import ReactNotification from 'react-notifications-component';
import { BrowserRouter } from 'react-router-dom';

const App = () => (
  <BrowserRouter>
    <ReactNotification />
    <GlobalStyles />
    <Layout>
      <Navbar />
      <Main>
        <Routes />
      </Main>
    </Layout>
  </BrowserRouter>
);
export default App;
