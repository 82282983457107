import styled from "styled-components";

export const Overlay = styled.div`
    background: rgba(33, 38, 47, 0.8);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Container = styled.div`
    background: #FFF;
    width: 100%;
    max-width: ${props => (props.width ? props.width : '400px')};
    padding: 20px 30px;
    border-radius: 5px;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.05);
    text-align: left;
    position: relative;
`;

export const Header = styled.header`
    /* font-size: 8.75rem; */
    font-weight: 600;
    background-size: contain;
    text-align: center;
    padding-bottom: 15px;

    p {
        margin-top: 8px;
        color: #bac1cf;
        font-family: Lato, sans-serif;
        font-size: 13px;
        font-weight: normal;
        width: 100%;
        text-align: center;
        align-self: center;
    }
`;

export const Close = styled.button`
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: transparent;
    border: 0;
    font-size: 0;
    
    &:hover {
        cursor: pointer;
    }
`;