import React from 'react';
import { withRouter } from 'react-router';
import Header from '../../components/header';
import Form from '../../components/forms/clientes'
import {
    ClientesContainer,
    CadContainer,
} from './styles';


const ClientesEdit = ({ ...props }) => {

    return (
        <>
            <Header title="Clientes" subtitle='Editar revenda/cooperativa' align='center'/>
            <ClientesContainer>
                <CadContainer>
                    <Form type={2} data={props}/>
                </CadContainer>
            </ClientesContainer>
        </>
    )
}
export default withRouter(ClientesEdit);
