import React, { useState, useEffect } from 'react';
import Header from '../../components/header';
import Card from '../../components/card';
// import Counter from '../../components/counter';
import { 
    withRouter, 
    // Link 
} from 'react-router-dom';
import {
    Dashboard,
    Grid,
    Row,
    TitleCard,
    Label,
    Title,
    Text,
    SecretValue,
    // Estado,
} from './styles';
import { Bar, Doughnut } from 'react-chartjs-2';
import api from '../../services/api';
import Loading from '../../components/loading';
import { parseDateDMY } from '../../utils/formatData';
import { getApiKey } from '../../services/auth';

const Home = ({ ...props }) => {

    const [load, setLoad] = useState(false);
    const [isAgronomist] = useState(true);
    const [name, setName] = useState('');

    const [nomeCard, setNomeCard] = useState('');
    const [cpfCard, setCpfCard] = useState('');
    const [clienteCard, setClienteCard] = useState('');
    const [telefoneCard, setTelefoneCard] = useState('');
    const [cnpjCard, setCnpjCard] = useState('');
    const [cidadeCard, setCidadeCard] = useState('');
    const [createdCard, setCreatedCard] = useState('');
    const [loadingCard, setLoadingCard] = useState(true);
    const [erroLastUser, setErroLastUser] = useState(false);
    const [erroMostUsedRoutes, setErroMostUsedRoutes] = useState(false);
    const [erroMessageMostUsedRoutes, setErroMessageMostUsedRoutes] = useState('');
    
    const [data, setData] = useState([]);
    const [dataDoughnut, setDataDoughnut] = useState([]);
    const [countMyUsers, setCountMyUsers] = useState([]);
    const [erroMyUsers, setErroMyUsers] = useState(false);
    const [erroMessageMyUsers, setErroMessageMyUsers] = useState(false);

    const [dataBar, setDataBar] = useState([]);
    const [erroTotalRequests, setErroTotalRequests] = useState(false);
    const [erroMessageTotalRequests, setErroMessageTotalRequests] = useState(false);
    const [hideApiKey, setHideApiKey] = useState(true);
    const [apiKey, setApiKey] = useState('');

    // const data = {
    //     labels: ['Rota1', 'Rota2', 'Rota3', 'Rota4', 'Rota5', 'Rota6', 'Rota7', 'Rota8', 'Rota9', 'Rota10' ],
    //     datasets: [
    //       {
    //         label: '',
    //         data: [39000, 21300, 20000, 15000, 15000, 10700, 10000, 5000, 4700, 3000],
    //         backgroundColor: [
    //           'rgba(0, 180, 63, 0.3)',
    //           'rgba(0, 180, 63, 0.3)',
    //           'rgba(0, 180, 63, 0.3)',
    //           'rgba(0, 180, 63, 0.3)',
    //           'rgba(0, 180, 63, 0.3)',
    //           'rgba(0, 180, 63, 0.3)',
    //           'rgba(0, 180, 63, 0.3)',
    //           'rgba(0, 180, 63, 0.3)',
    //           'rgba(0, 180, 63, 0.3)',
    //           'rgba(0, 180, 63, 0.3)',
    //         ],
    //         borderColor: [
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(0, 180, 63, 1)',
    //         ],
    //         borderWidth: 1,
    //       },
    //     ],
    // };
    const options = {
        indexAxis: 'y',
        
        scales: {
            y: {
                ticks: {mirror: true},
            },
            // yAxes: [{ticks: {mirror: true}}],
        },

        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        maintainAspectRatio: true,
        // aspectRatio: 2,

        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: false,
            },
        },
    };
    
    // const dataDoughnut = {
    //     labels: ['Usuários cadastrados', 'Usuários disponíveis'],
    //     datasets: [
    //       {
    //         label: '',
    //         data: [5, 2],
    //         backgroundColor: [
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(240, 240, 240, 1)',
    //         ],
    //         borderColor: [
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(255, 255, 255, 1)',
    //         ],
    //         borderWidth: 1,
    //       },
    //     ],
    // };
    const optionsDoughnut = {
        // indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide

        responsive: true,
        maintainAspectRatio: true,
        // aspectRatio: 2,

        plugins: {
            legend: {
                position: 'top',
                display: false,
                // maxHeight: 300,
                labels: {
                    font: {
                        size: 11
                    },
                },
            },
            title: {
                display: false,
                text: 'Meus usuários',
                color: '#bac1cf',
                font: {
                    size: 11,
                    weight: 'normal',
                    stretch: 'normal',
                    style: 'normal',
                },
            },
        },
    };

    // const dataBar = {
    //     labels: ['Março', 'Abril', 'Maio' ],
    //     datasets: [
    //       {
    //         label: '',
    //         data: [5000, 4130, 2000],
    //         backgroundColor: [
    //           'rgba(0, 180, 63, 0.3)',
    //           'rgba(0, 180, 63, 0.3)',
    //           'rgba(0, 180, 63, 0.3)',
    //         ],
    //         borderColor: [
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(0, 180, 63, 1)',
    //           'rgba(0, 180, 63, 1)',
    //         ],
    //         borderWidth: 1,
    //       },
    //     ],
    // };
    const optionsBar = {
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        maintainAspectRatio: true,
        // aspectRatio: 2,

        plugins: {
            legend: {
                position: 'top',
                display: false,
                // maxHeight: 300,
                labels: {
                    font: {
                        size: 11
                    },
                },
            },
            title: {
                display: false,
                text: 'Total de requisições',
                color: '#bac1cf',
                font: {
                    size: 11,
                    weight: 'normal',
                    stretch: 'normal',
                    style: 'normal',
                },
            },
        },
    };

    const getLastUser = async () => {
        try {
            const response = await api.get('/agronomos?limit=1&offset=0&order=[created,DESC]');

            if (response) {
                setLoadingCard(false);
                const lastUser = response.data.data;

                setNomeCard(lastUser[0].nome);
                setCpfCard(lastUser[0].cpf);
                setClienteCard(lastUser[0].filial.nome_fantasia);
                setTelefoneCard(lastUser[0].filial.telefone);
                setCnpjCard(lastUser[0].filial.cnpj);
                setCidadeCard(lastUser[0].filial.cidade);

                const date = parseDateDMY(lastUser[0].created);
                setCreatedCard(date);
                // parseDateDMY(lastUser[0].filial.created);

                // console.log(lastUser[0]);
                setLoadingCard(false);
                setErroLastUser(false);

            }
        } catch (error) {
            setLoadingCard(false);
            setErroLastUser(true);
            console.log("ERRO")
        }
    };

    const getMostUsedRoutes = async (id) => {
        try {
            setErroMostUsedRoutes(false);
            const response = await api.get(`/dev/most_used_routes/${id}`);
            if (response) {
                const data = response.data.data;

                let labels = [];
                let methods = [];
                let datas = [];
                let colors = [];
                let borderColors = [];

                if (data.length < 5) {
                    setErroMostUsedRoutes(true);
                    setErroMessageMostUsedRoutes("Ainda não há dados o suficiente para exibir");
                } else {
                    for (let i = 0; i <10; i++) {
                        if (i < data.length) {
                            methods[i] = data[i].method;
                            
                            let splitRoute = data[i].route.split('/v1');
                            
                            labels[i] = `${data[i].method} => ${splitRoute[1]}`;
    
                            datas[i] = parseInt(data[i].total);
    
                            if (methods[i] === 'GET') {
                                //color GET
                                    //background: 'rgba(88, 155, 255, 0.3)',
                                    //border: 'rgba(88, 155, 255, 1)',
        
                                colors[i] = 'rgba(88, 155, 255, 0.3)';
                                borderColors[i] = 'rgba(88, 155, 255, 1)';
        
                            } else if (methods[i] === 'POST') {
                                //color POST
                                    //background: 'rgba(0, 180, 63, 0.3)',
                                    //border: 'rgba(0, 180, 63, 1)',
        
                                colors[i] = 'rgba(0, 180, 63, 0.3)';
                                borderColors[i] = 'rgba(0, 180, 63, 1)';
        
                            } else if (methods[i] === 'PUT') {
                                //color PUT
                                    //background: 'rgba(252, 161, 48, 0.3)',
                                    //border: 'rgba(252, 161, 48, 1)',
        
                                colors[i] = 'rgba(252, 161, 48, 0.3)';
                                borderColors[i] = 'rgba(252, 161, 48, 1)';
        
                            } else if (methods[i] === 'DELETE') {
                                //color DELETE
                                    //background: 'rgba(255, 82, 82, 0.3)',
                                    //border: 'rgba(255, 82, 82, 1)',
        
                                colors[i] = 'rgba(255, 82, 82, 0.3)';
                                borderColors[i] = 'rgba(255, 82, 82, 1)';
        
                            }
                        }
                    }
    
                    setData({
                        labels: labels,
                        datasets: [{
                            label: '',
                            data: datas,
                            backgroundColor: colors,
                            borderColor: borderColors,
                            borderWidth: 1,
                        }]
                    })
                }

            }
        } catch (error) {
            // console.log("Erro ao capturar dados de rotas mais usadas");
            setErroMostUsedRoutes(true);
            if (error.response.data.errors) {
                setErroMessageMostUsedRoutes(`Ocorreu um problema ao carregar os dados: ${error.response.data.errors}`);
            } else {
                setErroMessageMostUsedRoutes(`Ocorreu um problema ao carregar os dados!`);
            }
        }
    };

    const getMyUsers = async (id) => {
        try {
            const response = await api.get(`/dev/my_users/${id}`);
            if (response) {
                setErroMyUsers(false);
                const users = response.data.data[0];
                // console.log(users);

                // console.log(users.total);
                setCountMyUsers({totalCadastrado: users.total_cadastrado, totalAdquirido: users.total_adquirido})
                let falta = 0;

                falta = parseInt(users.total_adquirido) - parseInt(users.total_cadastrado);

                const data = {
                    labels: ['Usuários cadastrados', 'Usuários disponíveis'],
                    datasets: [
                      {
                        label: '',
                        data: [parseInt(users.total_cadastrado), falta],
                        backgroundColor: [
                          'rgba(0, 180, 63, 1)',
                          'rgba(240, 240, 240, 1)',
                        ],
                        borderColor: [
                          'rgba(0, 180, 63, 1)',
                          'rgba(255, 255, 255, 1)',
                        ],
                        borderWidth: 1,
                      },
                    ],
                };

                setDataDoughnut(data);
            }
        } catch (error) {
            // console.log("Erro ao capturar dados de usuários");
            setErroMyUsers(true);
            if (error.response.data.errors) {
                setErroMessageMyUsers(`Ocorreu um problema ao carregar os dados: ${error.response.data.errors}`);
            } else {
                setErroMessageMyUsers(`Ocorreu um problema ao carregar os dados!`);
            }
            // console.log(error.response.status);
        }
    }

    const getTotalRequests = async (id) => {
        try {
            const response = await api.get(`/dev/total_requests/${id}`);
            if (response) {
                setErroTotalRequests(false);
                const requests = response.data.data;
                // console.log(requests);
                const meses = [ ' ', 
                                'Janeiro', 
                                'Fevereiro', 
                                'Março', 
                                'Abril', 
                                'Maio', 
                                'Junho', 
                                'Julho', 
                                'Agosto', 
                                'Setembro', 
                                'Outubro', 
                                'Novembro', 
                                'Dezembro', 
                              ];

                const labels = [];
                const datas = [];

                let initialCont = 0;
                console.log(requests.length);

                const cont = requests.length;

                if (cont > 3) {
                    initialCont = cont - 3;
                    console.log(initialCont);
                }

                console.log(initialCont);

                let loop = 0;
                for (let i = initialCont; i < requests.length; i++) {
                    if (loop < 3) {
                        labels[loop] = meses[parseInt(requests[i].mes)];
                        datas[loop] = parseInt(requests[i].total);
                    }
                    loop = loop + 1;
                    
                }
                
                const dataBar = {
                    labels: labels,
                    datasets: [
                      {
                        label: '',
                        data: datas,
                        backgroundColor: [
                          'rgba(0, 180, 63, 0.3)',
                          'rgba(0, 180, 63, 0.3)',
                          'rgba(0, 180, 63, 0.3)',
                        ],
                        borderColor: [
                          'rgba(0, 180, 63, 1)',
                          'rgba(0, 180, 63, 1)',
                          'rgba(0, 180, 63, 1)',
                        ],
                        borderWidth: 1,
                      },
                    ],
                };

                setDataBar(dataBar);
            }
        } catch (error) {
            setErroTotalRequests(true);
            if (error.response.data.errors) {
                setErroMessageTotalRequests(`Ocorreu um problema ao carregar os dados: ${error.response.data.errors}`);
            } else {
                setErroMessageTotalRequests(`Ocorreu um problema ao carregar os dados!`);
            }
            // console.log("Erro ao capturar dados do total de requisições");
            // console.log(error.response.status);
        }
    }

    const initializeComponent = () => {
        if (load === false) {
            setLoad(true);

            let obj = JSON.parse(localStorage.getItem("@userinfo"));
            if(obj){   
                getMostUsedRoutes(obj.id);
                getMyUsers(obj.id);
                getTotalRequests(obj.id);

                const regex = /[0-9]/;
                const regex2 = /[^a-z0-9]/gi;

                let email = obj.name;
                let nome = '';

                email = email.split('@');
                email = email[0];

                for (let i = 0; i < email.length; i++) {
                    if (regex.test(email)) {
                        email = email.replace(/[0-9]/, '');
                    }
                }

                if (regex2.test(email)) {
                    email = email.replace(regex2, ' ');
                    email = email.split(' ');
                    nome = email[0];
                    nome = nome.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    });
                } else {
                    nome = email;
                    nome = nome.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
                        return a.toUpperCase();
                    });
                }
                setName(nome);
            }
            getLastUser();

            let api = getApiKey();
            setApiKey(api);
        }
    }

    useEffect(() => {
        initializeComponent()
    });


    return(
        <>
            <Header title={`Olá, ${name}`} align='left'/>
            <Dashboard
                marginTop='-75px'
            >
                <Grid
                    width='650px'
                    height='auto'
                    justifyContent='flex-start'
                    flexDirection='column'
                    marginBottom='15px'
                    isResponsive={true}
                    isResponsiveWhenWidth='1217px'
                >
                    <Grid
                        width='650px'
                        height='250px'
                        justifyContent='space-between'
                        isResponsive={true}
                        isResponsiveWhenWidth='1217px'
                        isResponsiveHeight='500px'
                    >
                        <Card
                            width='100%'
                            height='100%'
                            flexDirection='collumn'
                            alignItems='left'
                            padding='27px 25px 15px 25px'
                            marginBottom='25px'
                        >
                            <Row>
                                <TitleCard>ÚLTIMO USUÁRIO CADASTRADO</TitleCard>
                            </Row>

                            <Row>
                                <hr></hr>
                            </Row>

                            {isAgronomist && !erroLastUser ? (
                                <>
                                    {!loadingCard ? (
                                        <>
                                            <Row>
                                                <Label>Nome</Label>
                                                <Title>{nomeCard}</Title>
                                            </Row>
                                            <Row
                                                flexDirection='row'
                                                justifyContent='space-around'
                                            >
                                                <Grid
                                                    width='33%'
                                                    height='auto'
                                                >
                                                    <Label>CPF</Label>
                                                    <Text>{cpfCard}</Text>
                                                </Grid>
                                                <Grid
                                                    width='33%'
                                                    height='auto'
                                                >
                                                    <Label>Cliente</Label>
                                                    <Text>{clienteCard}</Text>
                                                </Grid>
                                                <Grid
                                                    width='33%'
                                                    height='auto'
                                                >
                                                    <Label>Telefone</Label>
                                                    <Text>{telefoneCard}</Text>
                                                </Grid>
                                            </Row>
                                            <Row
                                                flexDirection='row'
                                                justifyContent='space-around'
                                            >
                                                <Grid
                                                    width='33%'
                                                    height='auto'
                                                >
                                                    <Label>CNPJ</Label>
                                                    <Text>{cnpjCard}</Text>
                                                </Grid>
                                                <Grid
                                                    width='33%'
                                                    height='auto'
                                                >
                                                    <Label>Cidade</Label>
                                                    <Text>{cidadeCard}</Text>
                                                </Grid>
                                                <Grid
                                                    width='33%'
                                                    height='auto'
                                                >
                                                    <Label>Data de registro</Label>
                                                    <Text>{createdCard}</Text>
                                                </Grid>
                                            </Row>
                                        

                                        </>
                                    ) : (
                                        <Loading width='50px' height='50px' heightContainer = '150px' />
                                    )}
                                </>
                            ) : (
                                <Row>
                                    <h4 style={{
                                        textAlign: 'center',
                                        paddingTop: '10%',
                                        paddingBottom: '10%',
                                    }}>Ainda não há nenhum usuário cadastrado!</h4>
                                </Row>
                            )}
                            

                        </Card>
                    </Grid>
                    
                    <Grid
                        width='650px'
                        height='250px'
                        justifyContent='space-between'
                        canvasWidth='260px'
                        canvasHeigth='260px'
                        marginTop='15px'
                        isResponsive={true}
                        isResponsiveWhenWidth='1217px'
                        isResponsiveHeight={true}
                    >
                        <Card
                            width='45%'
                            height='250px'
                            // flexDirection='column'
                            alignItems='flex-start'
                            padding='30px'
                            justifyContent='center'
                            isResponsive={true}
                            isResponsiveWhenWidth='630px'
                        >
                            <Label textAlign='center' marginTop='-15px' marginBottom='15px'>Meus usuários</Label>

                            <Grid
                                width='162px'
                                height='100%'
                                justifyContent='center'
                                canvasWidth='162px'
                                canvasHeigth='162px'
                            >
                                {!loadingCard && !erroMyUsers ? (
                                    <>
                                        <Doughnut data={dataDoughnut} options={optionsDoughnut} width='162' height='162'/>
                                        <Title textAlign='center'>{countMyUsers.totalCadastrado}/{countMyUsers.totalAdquirido}</Title>
                                    </>
                                ) : (
                                    <>
                                        {erroMyUsers ? (
                                            <h4 style={{
                                                margin: 'auto',
                                                textAlign: 'center'
                                            }}>{erroMessageMyUsers}</h4>
                                        ) : (
                                            <Loading width='50px' height='50px' heightContainer='150px' />
                                        )}
                                    </>
                                )}
                            </Grid>

                        </Card>
                        
                        <Card
                            width='45%'
                            height='250px'
                            alignItems='flex-start'
                            padding='30px'
                            isResponsive={true}
                            isResponsiveWhenWidth='630px'

                        >
                            {/* <Label textAlign='center'>Total de requisições</Label>
                            <Counter from='1500' /> */}
                            <Label textAlign='center' marginTop='-13px' marginBottom='5px'>Total de requisições</Label>
                            <Grid
                                width='230px'
                                height='100%'
                                justifyContent='center'
                                canvasWidth='230px'
                                canvasHeigth='200px'
                            >   
                                {!loadingCard && !erroTotalRequests ? (
                                    <>
                                        <Bar data={dataBar} options={optionsBar} width='230' height='200'/>
                                    </>
                                ) : (

                                    <>
                                        {erroTotalRequests ? (
                                            <h4 style={{
                                                margin: 'auto',
                                                textAlign: 'center'
                                            }}>{erroMessageTotalRequests}</h4>
                                        ) : (
                                            <Loading width='50px' height='50px' heightContainer='150px' />
                                        )}
                                    </>
                                )}
                            </Grid>

                        </Card>
                    
                    </Grid>
                    

                </Grid>
                
                <Grid
                    width='300px'
                    height='525px'
                    isResponsive={true}
                    isResponsiveWhenWidth='1217px'
                    // isResponsiveMarginTop='5px'
                >   
                    <Card
                        width='100%'
                        height='518px'
                        flexDirection='collumn'
                        alignItems='left'
                        padding='27px 25px 15px 25px'
                        marginBottom='25px'
                        isResponsive={true}
                        isResponsiveWhenWidth='1217px'
                    >

                        <Row>
                            <TitleCard>ROTAS MAIS USADAS</TitleCard>
                        </Row>

                        <Row>
                            <hr></hr>
                        </Row>

                        <Grid
                            width='100%'
                            height='86%'
                            // marginTop='-35px'
                        >
                            <Grid
                                width='250px'
                                height='430px'
                                canvasWidth='250px'
                                canvasHeigth='430px'
                                marginTop='-15px'
                            >
                                {!loadingCard && !erroMostUsedRoutes? (
                                    <>
                                        <Bar 
                                            data={data} 
                                            options={options} 
                                            width='250' 
                                            height='430'
                                        />
                                    </>
                                ) : (
                                    <>
                                        {erroMostUsedRoutes ? (
                                            <h4 style={{
                                                margin: 'auto',
                                                textAlign: 'center'
                                            }}>{erroMessageMostUsedRoutes}</h4>
                                        ) : (
                                            <Loading width='50px' height='50px' heightContainer='330px' />
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>

                    </Card>
                    
                </Grid>


                <Row 
                    flexDirection='row'
                    onClick={() => setHideApiKey(!hideApiKey)}
                >
                    <Grid
                        width='300px'
                        height='50px'
                        isResponsive={true}
                        isResponsiveWhenWidth='1217px'
                        // isResponsiveMarginTop='5px'
                    >  
                        <Card
                            width='100%'
                            height='100%'
                            flexDirection='row'
                            alignItems='center'
                            padding='5px'
                            marginBottom='25px'
                            onClick={() => setHideApiKey(!hideApiKey)}
                        >
                            <p onClick={() => setHideApiKey(!hideApiKey)} >Minha ApiKey: </p>
                            <SecretValue 
                                onClick={() => setHideApiKey(!hideApiKey)}
                                width='auto'
                                isHide={hideApiKey}
                            >
                                <p style={{
                                    marginLeft: '10px'
                                }}>{apiKey}</p>
                            </SecretValue>
                        </Card>
                    </Grid>
                </Row>
            </Dashboard>
        </>
    )
}

export default withRouter(Home);