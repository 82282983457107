import React, { useState, useEffect } from 'react';
import Input from '../../input';
import Button from '../../button';
import { store } from "react-notifications-component";
import validadorDeCpf from '../../../services/validadorCpf';
import api from '../../../services/api';

import {
    UsuariosContainer,
    CadContainer,
} from './styles';
import Select from '../../select';
import { formatClientsForSelect } from '../../../utils/formatData';


const UsuariosForm = ({ ...props }) => {

    const [load, setLoad] = useState(false);
    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [email, setEmail] = useState('');
    const [cpfIsValid, setCpfIsValid] = useState(false);
    const [cliente, setCliente] = useState('');
    const [clientes, setClientes] = useState([]);

    const [statusOptions] = useState([
        {value: '1', label: 'Ativo'},
        {value: '0', label: 'Inativo'}
    ]);
    const [status, setStatus] = useState('');


    const changeNome = (value) => {
        setNome(value);
    }
    const changeCpf = (value) => {
        setCpf(value);
        const input = document.getElementById('cpf');
        input.classList.remove("error");
    }
    
    const changeEmail = (value) => {
        if (value !== email) {
            setEmail(value);
        }
    }
    const changeCliente = (value) => {
        if (value !== cliente) {
            setCliente(value);
        }
    }
    const changeStatus = (value) => {
        if (value !== status) {
            setStatus(value);
        }
    }


    const validaCpf = () => {
        if (cpf) {
            const isValido = validadorDeCpf(cpf);

            if (isValido) {
                setCpfIsValid(true);
                const input = document.getElementById('cpf');
                input.classList.remove("error");
            } else {
                setCpfIsValid(false);
                const input = document.getElementById('cpf');
                input.classList.add("error");

                store.addNotification({
                    title: "Erro!",
                    message: `Informe um CPF válido!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }

    const getClients = async () => {
        try {
            const response = await api.get('/filiais');

            const clients = formatClientsForSelect(response.data.data);
            setClientes(clients);

        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const getUser = async (idUser) => {
        try {
            const response = await api.get(`/agronomos/${idUser}`);

            if (response) {
                const user = response.data.data;
                // changeCliente({value: user.filial.id, label: user.filial.nome_fantasia});
                changeCliente(user.filial.id);
                changeNome(user.nome);
                changeCpf(user.cpf);
                changeEmail(user.email);
                changeStatus(`${user.status}`);
                if (user.cpf) {
                    const isValido = validadorDeCpf(user.cpf);
        
                    if (isValido) {
                        setCpfIsValid(true);
                        const input = document.getElementById('cpf');
                        input.classList.remove("error");
                    } else {
                        setCpfIsValid(false);
                        const input = document.getElementById('cpf');
                        input.classList.add("error");
        
                        store.addNotification({
                            title: "Erro!",
                            message: `Informe um CPF válido!`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                }
            }

            // console.log(response);
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }


    const handleUser = async () => {
        if (nome && cpf && cpfIsValid && (cliente || cliente.value)) {

            const payload = {
                nome,
                cpf,
                email,
                agro_subsidiary_id: cliente.value || cliente,
                senha: '',
                usuario: '',
                status: props.type === 1 ? 1 : status.value || status,
            }
            if (props.type === 1) {
                // Cadastrar Usuário
                try {
                    const response = await api.post('/agronomos', payload)

                    if (response.status === 200) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: `Usuário cadastrado com sucesso!`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });  
                        setTimeout(() => {
                            props.prop.history.push('/usuarios');      
                        }, 500);
                    }
                } catch (error) {
                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });    
                }
            } else if (props.type === 2) {
                // Editar usuário
                try {
                    const response = await api.put(`/agronomos/${props.data.match.params.id}`, payload);

                    if (response) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: `Usuário cadastrado com sucesso!`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });  
                        setTimeout(() => {
                            props.data.history.push('/usuarios');
                        }, 500);
                    }
                } catch (error) {
                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });  
                }
            }
        } else {

            if (!cpfIsValid) {
                store.addNotification({
                    title: "Erro!",
                    message: `Informe um CPF válido!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Preencha todos os campos`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }

    const initializeComponent = () => {
        if(load === false) {
            setLoad(true);
            getClients();
    
            if (props.type === 2) {
                if (props.data) {
                    getUser(props.data.match.params.id);
                }
            }
        }

    }

    useEffect(() => {
        initializeComponent();
    })

    return (
        <>
            <UsuariosContainer>
                <CadContainer>
                    <Select 
                        id='cliente'
                        label='Cliente'
                        placeholder='Cliente'
                        value={cliente}
                        onChange={(e) => changeCliente(e)}
                        options={clientes}
                    />
                    <Input 
                        id='nome'
                        label='Nome'
                        placeholder='Nome'
                        value={nome}
                        onChange={(e) => changeNome(e.target.value)}
                    />
                    <Input 
                        id='cpf'
                        label='CPF'
                        placeholder='CPF'
                        mask='999.999.999-99'
                        value={cpf}
                        onChange={(e) => changeCpf(e.target.value)}
                        onBlur={() => validaCpf()}
                    />
                    <Input 
                        id='email'
                        label='E-mail'
                        placeholder='E-mail'
                        value={email}
                        onChange={(e) => changeEmail(e.target.value)}
                    />
                    {props.type === 2 ? (
                        <Select 
                            id='status'
                            label='Status'
                            placeholder='Status'
                            value={status}
                            onChange={(e) => changeStatus(e)}
                            options={statusOptions}
                        />
                    ) : (null)}
                    <Button onClick={() => handleUser()}>
                        {props.type === 1 ? 'Cadastrar' : 'Salvar'}
                    </Button>
                </CadContainer>
            </UsuariosContainer>
        </>
    )
}
export default UsuariosForm;
