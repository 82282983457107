import styled from 'styled-components';

export const ButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '50px'};
  border: ${props => (props.border ? props.border : `1px solid ${props.color}`)};
  border-radius: 30px;
  color: ${props => (props.outline ? props.color : "#fff")};
  font-weight: bold;
  font-size: ${props => props.fontSize || "11px"};
  background: ${props => (props.outline ? "transparent" : props.color)};
  margin: ${props => props.margin ? props.margin : "0px"};
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    background: ${props => props.color};
    color: #FFF;
    box-shadow: 0px 0px 6px 1px ${props => props.color};
  }
  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
`;
