import React from 'react';
import { withRouter } from 'react-router';
import Header from '../../components/header';
import Form from '../../components/forms/empresa'

import {
    EmpresaContainer,
    CadContainer,
} from './styles';

const Empresa = ({ ...props }) => {

    return (
        <>
            <Header title="Minha empresa" subtitle='Greencie os dados de sua empresa' align='center'/>
            <EmpresaContainer>
                <CadContainer>
                    {props ? (
                        <Form 
                            type={2} 
                            prop={props} 
                            // data={idEmpresa}
                        />
                    ) : (
                        <h2>Carregando informações...</h2>
                    )}
                </CadContainer>
            </EmpresaContainer>
        </>
    )
}
export default withRouter(Empresa);
