import React from 'react';
import { CardContainer } from './styles';

const Card = ({ children, ...props }) => {
    return(
        <CardContainer
            width={props.width}
            height={props.height}
            justifyContent={props.justifyContent}
            alignItems={props.alignItems}
            padding={props.padding}
            flexDirection={props.flexDirection}
            marginBottom={props.marginBottom}
            isResponsive={props.isResponsive}
            isResponsiveWhenWidth={props.isResponsiveWhenWidth}
        >
            {children}
        </CardContainer>
    );
}
export default Card;
