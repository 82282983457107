import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Login from './pages/login';
import Home from './pages/home';
import Relatorios from './pages/relatorios';
import Clientes from './pages/clientes';
import ClientesAdd from './pages/clientes/add';
import ClientesEdit from './pages/clientes/edit';
import Usuarios from './pages/usuarios';
import UsuariosAdd from './pages/usuarios/add';
import UsuariosEdit from './pages/usuarios/edit';
import Empresa from './pages/empresa';
import NotFound from "./pages/404/index";
import Senha from './pages/senha';



import { isAuthenticated } from './services/auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <>
            <Route 
                {...rest}

                render={props =>
                isAuthenticated() ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to={{ pathname: '/login', state: { from: props.location } }}
                        />
                    )
                }
            />
        </>
    )
}

const Routes = () => (
    <Switch>
        <PrivateRoute exact path='/' component={Home} />
        <PrivateRoute exact path='/relatorios' component={Relatorios} />
        <PrivateRoute exact path='/clientes' component={Clientes} />
        <PrivateRoute exact path='/clientes/add' component={ClientesAdd} />
        <PrivateRoute exact path='/clientes/edit/:id' component={ClientesEdit} />
        <PrivateRoute exact path='/usuarios' component={Usuarios} />
        <PrivateRoute exact path='/usuarios/add' component={UsuariosAdd} />
        <PrivateRoute exact path='/usuarios/edit/:id' component={UsuariosEdit} />
        <PrivateRoute exact path='/empresa' component={Empresa} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/senha/:ticket' search="/:ticket" component={Senha} />
        <Route path="*" component={NotFound} />
    </Switch>
)
export default Routes;
