import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { withRouter } from 'react-router';
import Header from '../../components/header';
import Input from '../../components/input-search';
import Button from '../../components/button';
import Table from '../../components/table';
import { store } from "react-notifications-component";
import {
    ClientesContainer,
    SearchAndAdd,
} from './styles';
import { formatClientsForTable } from '../../utils/formatData';
import Loading from '../../components/loading';

const Clientes = ({ ...props }) => {

    const [load, setLoad] = useState(false);
    const [tableInfo, setTableInfo] = useState([])
    const [search, setSearch] = useState('');

    const onChangeSearch = (value) => {
        if (value !== search) {
            setSearch(value);
        }
    }
    
    const searchUsers = (value) => {
        console.log(`Pesquisar por '${value}'`);
    }

    const typedKey = (key, keyCode) => {
        if (key === 'Enter' && keyCode === 13) {
            searchUsers(search);
        }
    }

    const getClients = async () => {
        try {
            const response = await api.get('/filiais');
            const clients = formatClientsForTable(response.data.data);

            const columns = ['Nome fantasia', 'Subsidiary_id', 'Razão social', 'Telefone', 'Cidade', 'Estado'];
            const data = clients;

            setTableInfo({columns: columns, data: data})

            if (data.length === 0) {
                store.addNotification({
                    title: "Erro!",
                    message: `Nenhum cliente encontrado`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }

        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const initializeComponent = () => {
        if (load === false) {
            setLoad(true);
            setTimeout(() => {
                getClients();
            }, 500);
        }
    }

    const redirectTo = (location) => {
        props.history.push(location);
    }

    useEffect(() => {
        initializeComponent();
    })

    return (
        <>
            <Header title="Meus clientes" subtitle='revendas/cooperativas' align='center'/>
            <ClientesContainer>
                <SearchAndAdd>
                    <Input 
                        className='search'
                        placeholder='Nome fantasia'
                        onChange={(e) => onChangeSearch(e.target.value)}
                        value={search}
                        onKeyDown={(e) => typedKey(e.key, e.keyCode)}
                        onClickSearch={() => searchUsers(search)}
                    />
                    <Button onClick={() => redirectTo("/clientes/add")} > Adicionar cliente </Button>
                </SearchAndAdd>

                {tableInfo.columns && tableInfo.data ? (
                    <Table data={tableInfo} route={(id) => redirectTo(`/clientes/edit/${id}`)}/>
                ):(
                    <Loading />
                )}

            </ClientesContainer>
        </>
    )
}
export default withRouter(Clientes);
