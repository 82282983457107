import React, {useState, useEffect, useRef} from "react";
import { withRouter, Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

import IconMenuHamburger from "../../assets/icons/icons-8-menu.png";
import Logo from "../../assets/logo.png";
import Home from "../../assets/icons/icons8-speed.png";
import HomeHover from "../../assets/icons/icons8-speed-hover.png";
import Doc from "../../assets/icons/icons8-code-file.png";
import DocHover from "../../assets/icons/icons8-code-file-hover.png";
// import Rel from "../../assets/icons/icons-8-relatorio.png";
// import RelHover from "../../assets/icons/icons-8-relatorio-hover.png";
import User from '../../assets/icons/icons8-worker.png'
import UserHover from '../../assets/icons/icons8-worker-hover.png'
import Companies from '../../assets/icons/icons8-related-companies.png'
import CompaniesHover from '../../assets/icons/icons8-related-companies-hover.png'

import { store } from 'react-notifications-component';

import {
    NavBarContainer,
    NavItem,
    Collapse,
    // Dropdown,
    UserContainer,
    // Premium
} from "./styles";

const Navbar = ({ ...props }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [hide, setHide] = useState(false);
    const [opcoesUser, setOpcoesUser] = useState(false);
    const [user, setUser] = useState([]);
    const [name, setName] = useState('');
    const [splitPath, setSplitPath] = useState([]);

    const ref = useRef();


    const changeNavbar = () => {
        if (isOpen) {
            setIsOpen(!isOpen);
            
        } else {
            setIsOpen(!isOpen);
        }
    };

    const closeNav = () => {
        if (isMobile) {
            setIsOpen(false);
        }
    }

    const redirectTo = (location) => {
        props.history.push(location);
        closeNav();
    }

    const handleClickOutside = (event) => {
        // console.log(event.path[1].id);
        // console.log(event);
        if (isMobile) {
            if (isOpen) {
                if (event.path[3].id !== 'hover-menu') {
                    setIsOpen(false);
                }
            }
        }
    }

    const initialConstructor = () => {
        document.addEventListener("mousedown", handleClickOutside);
        setInterval(() => {
            if (window.screen.width <= 855) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }, 10);
    }

    const sair = () => {
        setOpcoesUser(false);
        redirectTo('/login');
    }

    const checkOrientationScreen = () => {

        // console.log(window.screen.width, ' X ', window.screen.height);

        if (window.screen.width < window.screen.height) {
            store.addNotification({
                title: "Informação!",
                message: "Experimente deitar seu dispositivo para uma melhor vizualização da tabela!",
                type: "info",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }

    }



    useEffect(() => {
        let splitPath = props.location.pathname.split('/');
        setSplitPath(splitPath);
        if (
            props.location.pathname === '/' ||
            props.location.pathname === '/relatorios' ||
            props.location.pathname === '/clientes' ||
            props.location.pathname === '/clientes/add' ||
            (splitPath[1] === 'clientes' && splitPath[2] === 'edit') ||
            props.location.pathname === '/usuarios' ||
            props.location.pathname === '/usuarios/add' ||
            (splitPath[1] === 'usuarios' && splitPath[2] === 'edit') ||
            props.location.pathname === '/empresa'
        ) {

            if (isMobile) {
                setIsOpen(false);

                if (props.location.pathname === '/clientes' || props.location.pathname === '/usuarios') {
                    checkOrientationScreen();
                }

            } else {
                setIsOpen(true);
            }
            setHide(false);
            
            const checkUser = () => {
                let obj = JSON.parse(localStorage.getItem("@userinfo"));
                
                if(obj){            
                    setUser({ user: obj })
                }
            }

            checkUser();
        } else {
            setTimeout(() => {
                setIsOpen(false);
                setHide(true);
                setOpcoesUser(false);

            }, 5);
        }
    },[props.location.pathname, isMobile])

    useEffect(() => {
        initialConstructor()
    })

    useEffect(() => {
        if (isMobile) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    },[isMobile]);

    useEffect(() => {
        try {
            setName(user.user.name);
        } catch (error) {
            
        }
    },[user]);

    return(
        <>
            <NavBarContainer
                ref={ref}
                hide={hide}
                isMobile={isMobile}
                isOpen={isOpen}
            >
                <div className="hamburger">
                    <img src={IconMenuHamburger} onClick={changeNavbar} alt=''/>
                </div>

                <div className="logo">
                    <Link onClick={closeNav} to="/">
                        <img src={Logo} alt="logo" />
                    </Link>
                </div>

                <div className="menu-flex">
                    <div className="menu-icons">
                        <SimpleBar style={{ width: "100%", height: "auto", overflow: "hidden", paddingBottom: '10px'}}>
                            <NavItem onClick={e => redirectTo("/")} hover={props.location.pathname === "/" ? true : false}>
                                <div id="hover-menu" >
                                    <div className="position">
                                        <Collapse show={isOpen}>
                                            {props.location.pathname === "/" ? (<img alt ='' src={HomeHover} width="20" height="20" />) : (<img alt='' src={Home} width="20" height="20" />)}
                                            <p>Painel</p>
                                        </Collapse>
                                    </div>
                                </div>
                            </NavItem>

                            <NavItem onClick={e => redirectTo("/clientes")} hover={props.location.pathname === "/clientes" || props.location.pathname === "/clientes/add" || (splitPath[1] === 'clientes' && splitPath[2] === 'edit') ? true : false}>
                                <div id="hover-menu" >
                                    <div className="position">
                                        <Collapse show={isOpen}>
                                            {props.location.pathname === "/clientes" || props.location.pathname === "/clientes/add" || (splitPath[1] === 'clientes' && splitPath[2] === 'edit') ? (
                                                <img alt ='' src={CompaniesHover} width="20" height="20" />
                                            ) : (
                                                <img alt='' src={Companies} width="20" height="20" />
                                            )}
                                            <p>Meus clientes</p>
                                        </Collapse>
                                    </div>
                                </div>
                            </NavItem>

                            <NavItem onClick={e => redirectTo("/usuarios")} hover={props.location.pathname === "/usuarios" || props.location.pathname === "/usuarios/add" || (splitPath[1] === 'usuarios' && splitPath[2] === 'edit') ? true : false}>
                                <div id="hover-menu" >
                                    <div className="position">
                                        <Collapse show={isOpen}>
                                            {props.location.pathname === "/usuarios" || props.location.pathname === "/usuarios/add" || (splitPath[1] === 'usuarios' && splitPath[2] === 'edit') ? (
                                                <img alt ='' src={UserHover} width="20" height="20" />
                                            ) : (
                                                <img alt='' src={User} width="20" height="20" />
                                            )}
                                            <p>Usuários</p>
                                        </Collapse>
                                    </div>
                                </div>
                            </NavItem>
                            
                            {/* <NavItem onClick={e => redirectTo("/relatorios")} hover={props.location.pathname === "/relatorios" ? true : false}>
                                <div id="hover-menu" >
                                    <div className="position">
                                        <Collapse show={isOpen}>
                                            {props.location.pathname === "/relatorios" ? (
                                                <img alt ='' src={RelHover} width="20" height="20" />
                                            ) : (
                                                <img alt='' src={Rel} width="20" height="20" />
                                            )}
                                            <p>Relatórios</p>
                                        </Collapse>
                                    </div>
                                </div>
                            </NavItem> */}
                            
                            <NavItem onClick={() => window.open('https://doc.agroreceita.com.br/', '_blank')} hover={props.location.pathname === "/doc" ? true : false}>
                                <div id="hover-menu" >
                                    <div className="position">
                                        <Collapse show={isOpen}>
                                            {props.location.pathname === "/doc" ? (
                                                <img alt ='' src={DocHover} width="20" height="20" />
                                            ) : (
                                                <img alt='' src={Doc} width="20" height="20" />
                                            )}
                                            <p>Documentação</p>
                                        </Collapse>
                                    </div>
                                </div>
                            </NavItem>
                        </SimpleBar>
                    </div>
                </div>
                
            </NavBarContainer>


            <UserContainer
                isMobile={isMobile}
                hide={hide}
                id="Userphoto"
                onClick={()=>setOpcoesUser(!opcoesUser)}
            >
                <div className="profile-picture">
                    {name ? name.substr(0, 2) : ""}
                </div>
            </UserContainer>      

            {opcoesUser ? (<>
                <div style={{position: 'absolute',transform: 'rotate(45deg)',right: '52px', top: '64px',zIndex: 1230,backgroundColor: 'white',width: '10px', height: '10px'}}></div>
                <div style={{ width: '150px',borderRadius: '10px', position: 'absolute',right: '10px', top: '68px',zIndex: 1230, backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', paddingBottom: '10px'}} id="opcoes_user">
                    <p
                        style={{
                            cursor: 'pointer', 
                            marginTop: '10px',
                            fontSize: '9px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: '1px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            color: '#8a92a2'
                        }}
                        onClick={e => {
                            redirectTo(`/empresa`)
                        }}
                    >MINHA EMPRESA</p>

                    <p
                        style={{
                            cursor: 'pointer',
                            marginTop: '10px',
                            fontSize: '9px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: '1px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            color: '#8a92a2'
                        }}
                        onClick={()=> sair()}
                    >SAIR</p>
                </div>
            </>):(null)}   
        </>
    )
}

export default withRouter(Navbar)
