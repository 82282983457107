import React, {useState, useEffect} from 'react'
import {
    TableContainer
} from './styles';
import Arrow from '../../assets/icons/icons8-up-down-arrow.png';

const Table = ({ ...props }) => {

    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [orderByColumn, setOrderByColumn] = useState('');
    const [LastOrderByColumn, setLastOrderByColumn] = useState('');
    const [isAsc, setIsAsc] = useState(true);
    const [isMobileScreen, setIsMobileScreen] = useState(false);


    const orderList = (i) => {

        if (LastOrderByColumn === '') {
            setIsAsc(true);
            setLastOrderByColumn(i);
            setOrderByColumn(i);
            setData([]);
    
            let items = [];
    
            data.map((d) => {
                return (items = [ ...items, {values: d.values, id: d.id}]);
            });
    
            items.sort(function (a, b) {            
                return (a.values[i] > b.values[i]) ? 1 : ((b.values[i] > a.values[i]) ? -1 : 0);
            });
            setData(items);

        } else {
            if (i === LastOrderByColumn) {
                let asc = !isAsc;
                setIsAsc(asc);
                setOrderByColumn(i);
                setData([]);
        
                let items = [];
        
                data.map((d) => {
                    return(items = [ ...items, {values: d.values, id: d.id}]);
                });

                if (asc) {
                    items.sort(function (a, b) {            
                        return (a.values[i] > b.values[i]) ? 1 : ((b.values[i] > a.values[i]) ? -1 : 0);
                    });
                    setData(items);
                } else {
                    items.sort(function (a, b) {            
                        return (a.values[i] < b.values[i]) ? 1 : ((b.values[i] < a.values[i]) ? -1 : 0);
                    });
                    setData(items);
                }
        
            } else {
                setIsAsc(true);
                setOrderByColumn(i);
                setData([]);
        
                let items = [];
        
                data.map((d) => {
                    return(items = [ ...items, {values: d.values, id: d.id}]);
                });
        
                items.sort(function (a, b) {            
                    return (a.values[i] > b.values[i]) ? 1 : ((b.values[i] > a.values[i]) ? -1 : 0);
                });
                setData(items);
                setLastOrderByColumn(i);
            }
        }

    }

    const checkOrientationScreen = () => {
        setInterval(() => {
            if (window.screen.width <= 855) {
                if (window.screen.width < window.screen.height) {
                    setIsMobileScreen(true);
                } else {
                    setIsMobileScreen(false);
                }
            }
        }, 10);
    }

    useEffect(() => {
        try {
            if (props.data) {
                if (props.data.columns.length > 0) {
                    setColumns(props.data.columns);
                }
                
                if (props.data.data.length > 0) {
                    if (data.length === 0) {
                        setData(props.data.data);
                    }
                }
            }
        } catch (error) {
            console.log("AGUARDAR DADOS")
        }
        
    },[props, data.length]);

    useEffect(() => {
        checkOrientationScreen();
    })

    return(
        <TableContainer>
            <table>
                <tbody>
                    <tr className='title'>
                        {columns.length > 0 ? (
                            <>
                                {columns.map((c, i) => (
                                    <>
                                        {isMobileScreen ? (
                                            <>
                                                {i <= 1 ? (
                                                    <th 
                                                        key={i}
                                                        width={`49%`}
                                                        onClick={() => orderList(i)}
                                                    > {c} {orderByColumn === i ? <img className='selectedColumn' alt='' src={Arrow} width='10px'/> : <img className='noSelectedColumn' alt='' src={Arrow} width='10px'/>} </th>
                                                ) : (null)}
                                            </>
                                        ) : (
                                            <th 
                                                key={i}
                                                width={`${99/columns.length}%`}
                                                onClick={() => orderList(i)}
                                            > {c} {orderByColumn === i ? <img className='selectedColumn' alt='' src={Arrow} width='10px'/> : <img className='noSelectedColumn' alt='' src={Arrow} width='10px'/>} </th>
                                        )}

                                    </>
                                ))}
                            </>
                        ) : (null)}
                    </tr>
                    {data.length > 0 ? (
                        <>
                            {data.map((d, i) => (
                                
                                <tr key={i} className='row' onClick={() => props.route(d.id)}>
                                    {d.status === 0 ? (
                                        <>
                                            {d.values.map((value, i) => (
                                                <>
                                                    {isMobileScreen ? (
                                                        <>
                                                            {i <= 1 ? (
                                                                <td 
                                                                    key={i}
                                                                    width={`49%`}
                                                                ><s>{value}</s></td>
                                                            ) : (null)}
                                                        </>
                                                    ) : (
                                                        <td 
                                                            key={i}
                                                            width={`${99/d.values.length}%`}
                                                        ><s>{value}</s></td>
                                                    )}
                                                </>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {d.values.map((value, i) => (
                                                <>
                                                    {isMobileScreen ? (
                                                        <>
                                                            {i <= 1 ? (
                                                                <td 
                                                                    key={i}
                                                                    width={`49%`}
                                                                >{value}</td>
                                                            ) : (null)}
                                                        </>
                                                    ) : (
                                                        <td 
                                                            key={i}
                                                            width={`${99/d.values.length}%`}
                                                        >{value}</td>
                                                    )}
                                                </>
                                            ))}
                                        </>
                                    )}
                                </tr>
                            ))}
                        </>
                    ) : (
                        <tr>
                            <td width='100%'> 
                                <h2 
                                    style={{
                                        textAlign: 'center',
                                        paddingTop: '20px',
                                        paddingBottom: '20px',
                                    }}
                                >
                                    Nenhum registro encontrado
                                </h2> 
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </TableContainer>
    )
}
export default Table;
