import React, { useState, useEffect } from 'react';
import Input from '../../input';
import Select from '../../select';
import Button from '../../button';
import { store } from 'react-notifications-component';
import viacep from '../../../services/viacep';
import api from '../../../services/api';

import {
    ClientesContainer,
    CadContainer,
    Line
} from './styles';

const ClientesForm = ({ ...props }) => {

    const [load, setLoad] = useState(false);
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [telefone, setTelefone] = useState('');    
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState([]);

    const [telefoneEmergencia, setTelefoneEmergencia] = useState('');
    
    const [estados] = useState([
        { value: "SP", label: "São Paulo" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "SC", label: "Santa Catarina" },
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapa" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espirito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piaui" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" },
        { value: "", label: "" }
    ])
    const [statusOptions] = useState([
        {value: '1', label: 'Ativo'},
        {value: '0', label: 'Inativo'}
    ]);
    const [status, setStatus] = useState('');

    const [file, setFile] = useState([]);
    const [namesfiles, setNamesfiles] = useState([]);
    const [textfile, setTextFile] = useState('ADICIONAR ANEXOS (JPG e PNG, MAX 3MB)');


    const changeNomeFantasia = (value) => {
        if (value !== nomeFantasia) {
            setNomeFantasia(value);
        }
    }
    const changeRazaoSocial = (value) => {
        if (value !== razaoSocial) {
            setRazaoSocial(value);
        }
    }
    const changeCnpj = (value) => {
        if (value !== cnpj) {
            setCnpj(value)
        }
    }
    const changeTelefone = (value) => {
        if (value !== telefone) {
            setTelefone(value);
        }
    }
    const changeTelefoneEmergencia = (value) => {
        if (value !== telefone) {
            setTelefoneEmergencia(value);
        }
    }
    const changeCep = (value) => {
        if (value !== cep) {
            setCep(value);
            const input = document.getElementById('cep');
            input.classList.remove("error");
        }
    }
    const changeLogradouro = (value) => {
        if (value !== logradouro) {
            setLogradouro(value);
        }
    }
    const changeNumero = (value) => {
        if (value !== numero) {
            setNumero(value);
        }
    }
    const changeBairro = (value) => {
        if (value !== bairro) {
            setBairro(value);
        }
    }
    const changeCidade = (value) => {
        if (value !== cidade) {
            setCidade(value);
        }
    }
    const changeEstado = (value) => {
        if (value !== estado) {
            setEstado(value);
        }
    }
    const changeStatus = (value) => {
        if (value !== status) {
            setStatus(value);
        }
    }


    const validaCep = async () => {
        try {
            if (cep) {
                const response = await viacep.get(cep + "/json");
                if (response.data.erro) {
                    const input = document.getElementById('cep');
                    input.classList.add("error");
    
                    changeLogradouro('');
                    changeBairro('');
                    changeCidade('');
                    changeEstado('');
    
                    store.addNotification({
                        title: "Erro!",
                        message: "CEP inserido é inválido.",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                } else {
                    const info = response.data;
        
                    const { logradouro, bairro, localidade, uf } = info;
                    
                    const input = document.getElementById('cep');
                    input.classList.remove("error");
                    
                    changeLogradouro(logradouro);
                    changeBairro(bairro);
                    changeCidade(localidade);
                    changeEstado(uf);
                }
            } else {
                const input = document.getElementById('cep');
                input.classList.remove("error");
            }
        } catch (error) {

            const input = document.getElementById('cep');
            input.classList.add("error");

            changeLogradouro('');
            changeBairro('');
            changeCidade('');
            changeEstado('');

            store.addNotification({
                title: "Erro!",
                message: "CEP inserido é inválido.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    }

    
    const handleUser = async () => {

        if (props.type === 1) {
            //cadastrar novo
            if (nomeFantasia && razaoSocial && cnpj && telefone && cep && logradouro && numero && bairro && cidade && estado) {

                const payload = {
                    nome_fantasia: nomeFantasia,
                    razao_social: razaoSocial,
                    logo: file,
                    cnpj,
                    telefone,
                    telefone_emergencia: telefoneEmergencia,
                    cep,
                    endereco: logradouro,
                    numero,
                    bairro,
                    cidade,
                    estado: estado.value || estado
                };

                try {
                    const response = await api.post('/filiais', payload);
                    if (response) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: `Cliente cadastrado com sucesso!`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                        setTimeout(() => {
                            props.prop.history.push('/clientes');
                        }, 500);
                    }
                } catch (error) {
                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
    
                // console.log("Cadastrar: ", payload)
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Preencha todos os campos`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } else if (props.type === 2) {
            //editar existente
            if (nomeFantasia && razaoSocial && cnpj && telefone && cep && logradouro && numero && bairro && cidade && estado) {
    
                const payload = {
                    nome_fantasia: nomeFantasia,
                    razao_social: razaoSocial,
                    logo: file,
                    cnpj,
                    telefone,
                    telefone_emergencia: telefoneEmergencia,
                    cep,
                    endereco: logradouro,
                    numero,
                    bairro,
                    cidade,
                    estado: estado.value || estado,
                    status: status.value || status
                };

                try {
                    const response = await api.put(`/filiais/${props.data.match.params.id}`, payload);
                    if (response) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: `Cliente editado com sucesso!`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                        setTimeout(() => {
                            props.data.history.push('/clientes');
                        }, 500);
                    }
                } catch (error) {
                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
    
                // console.log("Cadastrar: ", payload)
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Preencha todos os campos`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }


    }

    const getClient = async (id) => {
        try {
            const response = await api.get(`/filiais/${id}`);
            if (response.data.data) {
                const client = response.data.data;

                changeNomeFantasia(client.nome_fantasia);
                changeRazaoSocial(client.razao_social);
                changeCnpj(client.cnpj);
                changeTelefone(client.telefone);
                // changeTelefoneEmergencia(client.telefone_emergencia);
                changeCep(client.cep);
                changeLogradouro(client.endereco);
                changeNumero(client.numero);
                changeBairro(client.bairro);
                changeCidade(client.cidade);
                changeEstado(client.estado);
                changeStatus(`${client.status}`);
                changeTelefoneEmergencia(client.telefone_emergencia);
            }
        } catch (error) {
            
        }
    }


    const initializeComponent = () => {
        if (load === false) {
            setLoad(true)
            if (props.type === 2) {
                if (props.data) {
                    getClient(props.data.match.params.id);
                }
            }
        }
    }

    const onChange = async (e) => {
        if(e.target.files){
            if(e.target.files[0].type !== "image/png" && e.target.files[0].type !== "image/jpeg"){
                store.addNotification({
                    title: "Erro!",
                    message: `O arquivo selecionado não possui a extensão correta`,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                return;
            }
        let fil = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(fil[0]);

        reader.onload=(e)=>{
            setFile(e.target.result)
        }
            let names = namesfiles;
            names.push(e.target.files[0].name);

            setNamesfiles(names);

            setTextFile((<>
                {e.target.files[0].name}
            </>));
        }
    }

    useEffect(() => {
        initializeComponent();
    })


    return (
        <>
            <ClientesContainer>
                <CadContainer>
                    <Input 
                        id='nome_fantasia'
                        label='Nome fantasia'
                        placeholder='Nome fantasia'
                        value={nomeFantasia}
                        onChange={(e) => changeNomeFantasia(e.target.value)}
                    />
                    <Input 
                        id='razao_social'
                        label='Razão social'
                        placeholder='Razão social'
                        value={razaoSocial}
                        onChange={(e) => changeRazaoSocial(e.target.value)}
                    />
                    <Input 
                        id='cnpj'
                        label='CNPJ'
                        placeholder='CNPJ'
                        mask='99.999.999/9999-99'
                        value={cnpj}
                        onChange={(e) => changeCnpj(e.target.value)}
                    />
                    <Input 
                        id='telefone'
                        label='Telefone'
                        placeholder='Telefone'
                        mask='(99) 9999-9999'
                        value={telefone}
                        onChange={(e) => changeTelefone(e.target.value)}
                    />
                
                    <Input 
                        id='cep'
                        label='CEP'
                        placeholder='CEP'
                        mask='99999-999'
                        value={cep}
                        onChange={(e) => changeCep(e.target.value)}
                        onBlur={() => validaCep()}
                    />
                    <Input 
                        id='logradouro'
                        label='Logradouro'
                        placeholder='Logradouro'
                        value={logradouro}
                        onChange={(e) => changeLogradouro(e.target.value)}
                    />
                    <Input 
                        id='numero'
                        label='Número'
                        placeholder='Número'
                        value={numero}
                        onChange={(e) => changeNumero(e.target.value)}
                    />
                    <Input 
                        id='bairro'
                        label='Bairro'
                        placeholder='Bairro'
                        value={bairro}
                        onChange={(e) => changeBairro(e.target.value)}
                    />
                    <Input 
                        id='cidade'
                        label='Cidade'
                        placeholder='Cidade'
                        value={cidade}
                        onChange={(e) => changeCidade(e.target.value)}
                    />
                    {/* <Input 
                        id='estado'
                        label='Estado'
                        placeholder='Estado'
                        value={estado}
                        onChange={(e) => changeEstado(e.target.value)}
                    /> */}
                    <Select 
                        id='estado'
                        label='Estado'
                        placeholder='Estado'
                        estado={true}
                        value={estado}
                        onChange={(e) => changeEstado(e)}
                        options={estados}
                    />
                    {props.type === 2 ? (
                        <Select 
                            id='status'
                            label='Status'
                            placeholder='Status'
                            value={status}
                            onChange={(e) => changeStatus(e)}
                            options={statusOptions}
                        />
                    ) : (null)}
                    
                    <Line>
                        <div />
                        <p>Informações do Expedidor</p>
                    </Line>

                    <Input
                        name="telefone_emergencia"
                        type="text"
                        label="Telefone de emergência"
                        placeholder="Telefone de emergência"
                        id="telefone_emergencia"
                        value={telefoneEmergencia}
                        onChange={e => changeTelefoneEmergencia(e.target.value)}
                    />

                    <div class='input-wrapper' style={{ marginBottom: '40px' }}>
                        <div for='input-file' onClick={() => document.getElementById('input-file').click()}>
                            <p>{textfile}</p>
                            (Apenas: PNG, JPEG)
                        </div>

                        <input 
                            style={{marginTop: '20px'}}
                            type="file"
                            id="input-file"
                            name="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e)=> onChange(e)}
                        />
                        <span id='file-name'></span>
                        
                    </div>


                    {/* <label 
                        style={{
                            marginTop: '25px', 
                            marginBottom: '-2px', 
                            marginLeft: '-247px', 
                            fontSize: '12px', 
                            fontWeight: '700', 
                            color: '#8192a2'
                        }}
                    >Adicionar Logomarca: </label>
                    
                    <div class='input-wrapper'
                        style={{
                            marginTop: '30px'
                        }}
                    >
                        <label className='area' for='input-file' style={{
                            width: '100%'
                        }}>
                            {textfile}
                        </label>

                        <input 
                            style={{marginTop: '20px'}}
                            type="file"
                            id="input-file"
                            name="file"
                            accept="image/png, image/jpeg"
                            multiple
                            onChange={(e)=> onChange(e)}
                        />
                        <span id='file-name'></span>
                        
                    </div>   */}


                    <Button onClick={() => handleUser()}>
                        {props.type === 1 ? 'Cadastrar' : 'Salvar'}
                    </Button>

                </CadContainer>
            </ClientesContainer>
        </>
    )
}
export default ClientesForm;
