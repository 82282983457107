import React from 'react';
import { withRouter } from 'react-router';
import Header from '../../components/header';
import Form from '../../components/forms/usuarios'


import {
    UsuariosContainer,
    CadContainer,
} from './styles';


const UsuariosAdd = ({ ...props }) => {

    
    return (
        <>
            <Header title="Usuários" subtitle='Adicionar novo agrônomo' align='center'/>
            <UsuariosContainer>
                <CadContainer>
                    <Form type={1} prop={props}/>
                </CadContainer>
            </UsuariosContainer>
        </>
    )
}
export default withRouter(UsuariosAdd);
