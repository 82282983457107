import { Overlay, Container, Header, Close } from './style';
import closeIcon from '../../assets/icons/close.svg';
const Modal = ({ children, ...props }) => {
    return(
        <Overlay>
            <Container
                width={props.width}
            >
                <Header>
                    <h2>{props.title}</h2>
                    <p>{props.subtitle}</p>
                    <Close
                        onClick={() => props.close()}
                    >
                        <img src={closeIcon} alt='Fechar modal' style={{
                        width: '15px'
                    }}/></Close>
                </Header>
                {children}
            </Container>
        </Overlay>
    )
}

export default Modal;