import styled from 'styled-components';

export const ClientesContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};

    button {
        /* position: absolute; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 400px;
        height: 50px;
        border: 1px solid #00b43f;
        border-radius: 30px;
        color: #fff;
        font-weight: bold;
        font-size: 11px;
        background: #00b43f;
        margin-top: 32px;
        transition: all 0.3s;

        &:hover {
        cursor: pointer;
        background: #00b43f;
        color: #FFF;
        box-shadow: 0px 0px 6px 1px #00b43f;
        }
        &:disabled {
        cursor: auto;
        opacity: 0.5;
        }
        &:focus {
        outline: none;
        }
    }

    input[type='file'] {
        display: none;
    }

    .input-wrapper div {
        width: 100%;
        height: 108px;
        background-color: #fff;
        border-radius: 1px;
        border: 1px dashed #8a92a2;
        color: #8a92a2;
        margin-bottom: 16px;
        padding: 12px 20px;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            width: 100%;
            text-align: center;
            font-size: 15px;
        }
    }
    
    .input-wrapper div:hover {
        background-color: #DCDCDC;
    }
`;

export const Line = styled.div`
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;
    max-width: 400px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: rgb(138, 146, 162);

    div{
        width: 100%;
        background-color: #ced2dc;
        height: 0.5px;
        margin-bottom: 8px;
    }
`;

export const SearchAndAdd = styled.div`
    width: 100%;
    height: 50px;
    margin-top: -25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: row-reverse;
    /* background-color: blue; */
    img {
        &:hover{
        cursor: pointer;
        }
    }

    button {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 50px;
        border: 3px solid #FFF;
        border-radius: 30px;
        color: #fff;
        font-weight: bold;
        font-size: 11px;
        background: #00b43f;
        margin: 0px;
        transition: all 0.3s;

        &:hover {
        cursor: pointer;
        background: #00b43f;
        color: #FFF;
        box-shadow: 0px 0px 6px 1px #00b43f;
        }
        &:disabled {
        cursor: auto;
        opacity: 0.5;
        }
        &:focus {
        outline: none;
        }
    }

`;

export const CadContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .input-wrapper{
        width: 400px;
        height: 50px;
    }

    .input-wrapper .area {
        width: 100%; 
        background-color: #fff;
        border-radius: 1px;
        border: dashed 1px #8a92a2;
        color: #8a92a2;
        margin: 10px;
        padding: 12px 20px;
    }
      
    .input-wrapper label:hover {
        background-color: #DCDCDC
    }

    input[type='file'] {
        display: none
    }

    .input-wrapper label {
        width: 100%; 
        background-color: #fff;
        border-radius: 1px;
        border: 1px dashed #8a92a2;
        color: #8a92a2;
        margin: 10px;
        padding: 12px 20px
    }

`;
