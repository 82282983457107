import React from "react";
import { withRouter, Link } from "react-router-dom";

import NotFoundStyle from "./styles";
import Button from '../../components/button';

import Logo from "../../assets/logo.png";
import Img404 from "../../assets/404.png";

const NotFound = ({ ...props }) => {

    const back = () => {
        props.history.goBack();
    }

    return(
        <>
            <NotFoundStyle>
                <div className="header">
                    <Link to="/">
                        <img src={Logo} alt="logo" />
                    </Link>
                </div>

                <div className="container">
                    <div className="box">
                        <h1>Página não encontrada.</h1>
                        <p>Ops, a página solicitada não existe.</p>
                        <div className="buttons">
                            <Button 
                                style={{ outline:0, padding: "5px", width:"166px", height: "45px" }}
                                outline
                                color="#00b43f"
                                onClick={() => back()}
                            >VOLTAR</Button>

                            <Link to="/">
                                <Button 
                                    style={{ outline:0, padding: "5px", width:"166px", height: "45px" }}
                                    color="#00b43f"
                                >HOME <i className="fa fa-arrow-right"></i> </Button>
                            </Link>

                        </div>
                    </div>
                    <div className="box">
                        <img src={Img404} alt="imagem erro 404" />
                    </div>
                </div>
            </NotFoundStyle>
        </>
    )


}

export default withRouter(NotFound);
